import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Tab, Tabs, Typography, OutlinedInput, TextareaAutosize } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams, Link, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Circle } from "@mui/icons-material";
import timers from "timers-promises";
import dateFormat from "dateformat";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab]);
    return bb;
}

function downloadQRImage(dataURI, baseName) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    console.log("downloadQRImage");
    let b = dataURItoBlob(dataURI);
    var url = window.URL.createObjectURL(b);
    a.href = url;
    a.download = baseName + "_QR_Code.png";
    a.click();
    window.URL.revokeObjectURL(url);
}

export default function Device() {
    const [device, setDevice] = useState(null);
    const [deviceStatusLog, setDeviceStatusLog] = useState(null);
    const [deviceActionsLog, setDeviceActionsLog] = useState(null);
    const { device_uuid } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const [campaigns, setCampaigns] = useState(null);
    const [currentClientUUID, setCurrentClientUUID] = useState(null);
    const [currentCampaignUUID, setCurrentCampaignUUID] = useState(null);
    const [clients, setClients] = useState(null);
    const [deviceSerialNumber, setDeviceSerialNumber] = useState(null);
    const [deviceMacAddress, setDeviceMacAddress] = useState(null);
    const [podCountDialogOpen, setPodCountDialogOpen] = useState(false);
    const [selectedPodCountType, setSelectedPodCountType] = useState(false);
    const [totalPodsLoaded, setTotalPodsLoaded] = useState(0);
    const [deviceLocationName, setDeviceLocationName] = useState(null);
    const [deviceLocationNumber, setDeviceLocationNumber] = useState(null);
    const [deviceAddressStreet, setDeviceAddressStreet] = useState(null);
    const [deviceAddressCity, setDeviceAddressCity] = useState(null);
    const [deviceAddressState, setDeviceAddressState] = useState(null);
    const [deviceAddressPostalCode, setDeviceAddressPostalCode] = useState(null);
    const [deviceInstallDate, setDeviceInstallDate] = useState(null);
    const [issues, setIssues] = useState(null);
    const [numIssues, setNumIssues] = useState(0);

    let setNewTotal = useRef();
    let addToTotal = useRef();
    let total_pods_loaded = useRef();
    let total_pods_added = useRef();
    let podCountDialogContent = useRef();
    let podCountDialogSending = useRef();
    let podCountDialogMessage = useRef();
    let podCountCancelButton = useRef();
    let podCountCloseButton = useRef();
    let podCountUpdateButton = useRef();

    const handleClose = () => {
        setPodCountDialogOpen(false);
    };

    const history = useNavigate();

    const [consumerQRCode, setConsumerQRCode] = useState(null);
    const [operatorQRCode, setOperatorQRCode] = useState(null);

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    let arrTabs = ["Summary", "Status History"];

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    async function getCampaigns() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaigns", {
                company: "default",
            })
            .then((response) => {
                setCampaigns(response.data);
            })
            .catch(() => {});
    }

    async function getIssues(serial_number) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/device/issues/" + serial_number, {})
            .then((response) => {
                console.log(response.data);
                setIssues(response.data);
                setNumIssues(response.data.length);
            })
            .catch(() => {});
    }

    const reopenIssue = (issueID) => {
        console.log("RESOLVE" + process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID, {
                k: "issue_status",
                v: "open",
            })
            .then(() => {
                getData();
            })
            .catch(() => {});
    };

    const resolveIssue = (issueID) => {
        console.log("RESOLVE" + process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID, {
                k: "issue_status",
                v: "resolved",
            })
            .then(() => {
                getData();
            })
            .catch(() => {});
    };

    async function getClients() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/clients", {
                company: "default",
            })
            .then((response) => {
                setClients(response.data);
            })
            .catch(() => {});
    }

    async function getQRCode(codeType, codeContent = {}) {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/" + codeType + "_qr/write", codeContent)
            .then((response) => {
                console.log(response.data.imageData);
                switch (codeType) {
                    case "consumer":
                        setConsumerQRCode(response.data.imageData);

                        break;
                    case "operator":
                        setOperatorQRCode(response.data.imageData);
                        break;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const openPodCountDialog = () => {
        setPodCountDialogOpen(true);
        setSelectedPodCountType("");
    };

    const updateDeviceField = (k, v, save) => {
        device[k] = v;
        switch (k) {
            case "address_city":
                setDeviceAddressCity(v);
                break;
            case "address_state":
                setDeviceAddressState(v);
                break;
            case "address_street":
                setDeviceAddressStreet(v);
                break;
            case "address_postal_code":
                setDeviceAddressPostalCode(v);
                break;
            case "location_name":
                setDeviceLocationName(v);
                break;
            case "location_number":
                setDeviceLocationNumber(v);
                break;
            case "serial_number":
                setDeviceSerialNumber(v);
                break;
            case "mac_address":
                setDeviceMacAddress(v);
                break;
            case "install_date":
                let d = v.$y + "-" + eval(v.$M + 1) + "-" + v.$D;
                console.log(d);
                //                v = v.substring(0, 4);
                setDeviceInstallDate(v);
                break;
        }
        setDevice(device);
        console.log(device);

        if (save) {
            console.log("saveDeviceField");
            saveDeviceField(k);
        } else {
            console.log("ONDDD");
        }
    };

    const saveDeviceField = (k) => {
        if (k == "install_date") {
            console.log("INSTALL DATE");
        }
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_device_field/" + device_uuid, {
                k: k,
                v: device[k],
            })
            .then(() => {
                if (k == "serial_number") {
                    history("/devices/" + device[k]);
                    window.location.reload();
                    return false;
                }
                getData();
            })
            .catch(() => {});
    };

    async function getData() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/devices/" + device_uuid, {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                setDevice(response.data[0]);
                getDeviceStatusLog(response.data[0].serial_number);
                getDeviceActionsLog(response.data[0].serial_number);
                setCurrentCampaignUUID(response.data[0].campaign_uuid);
                setCurrentClientUUID(response.data[0].client_uuid);
                setDeviceSerialNumber(response.data[0].serial_number);
                setDeviceMacAddress(response.data[0].mac_address);
                setTotalPodsLoaded(response.data[0].remaining_pods);
                setDeviceLocationName(response.data[0].location_name);
                setDeviceLocationNumber(response.data[0].location_number);
                setDeviceAddressStreet(response.data[0].address_street);
                setDeviceAddressCity(response.data[0].address_city);
                setDeviceAddressState(response.data[0].address_state);
                setDeviceAddressPostalCode(response.data[0].address_postal_code);
                setDeviceInstallDate(response.data[0].install_date);

                getIssues(response.data[0].serial_number);

                getQRCode("consumer", {
                    campaign_uuid: response.data[0].campaign_uuid,
                    serial_number: response.data[0].serial_number,
                });
                getQRCode("operator", {
                    serial_number: response.data[0].serial_number,
                });
            })
            .catch(() => {});
    }

    async function getDeviceStatusLog(serial_number) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/device_status_log/" + serial_number, {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                setDeviceStatusLog(response.data);
            })
            .catch(() => {});
    }

    async function getDeviceActionsLog(serial_number) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/device/operator_actions/" + serial_number, {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                setDeviceActionsLog(response.data);
            })
            .catch(() => {});
    }

    const removeDevice = () => {
        MySwal.fire({
            title: <p>Remove this User?</p>,
            html: <p>Are you sure you want to remove this device?</p>,
            cancelButtonText: "Cancel",
            confirmButtonText: "Remove Device",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/device/remove", {
                        serial_number: device_uuid,
                    })
                    .then(() => {
                        history("/devices");
                    })
                    .catch(() => {});
            } else {
            }
        });
    };
    const updatePodCount = (serial_number) => {
        let newPodCount;
        if (selectedPodCountType === "change_total") {
            newPodCount = parseInt(total_pods_loaded.current.value);
        } else {
            newPodCount = parseInt(total_pods_added.current.value) + parseInt(device.remaining_pods);
        }

        if (selectedPodCountType === "" || !newPodCount) {
            alert("Pod count and fill type required");
            return false;
        }

        if (newPodCount === device.remaining_pods) {
            alert("Count needs to be different from current count.");
            return false;
        }

        podCountCancelButton.current.style.display = "none";
        podCountUpdateButton.current.style.display = "none";
        podCountDialogContent.current.style.display = "none";
        podCountDialogMessage.current.style.color = "black";
        podCountDialogMessage.current.style.fontWeight = "normal";
        podCountDialogMessage.current.innerHTML = "Sending Command...";
        podCountDialogSending.current.style.display = "inline";

        console.log(newPodCount);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/device/send_command/" + serial_number, {
                command: "set_remaining_pods",
                value: parseInt(newPodCount),
            })
            .then(async () => {
                // wait for 3 seconds
                await timers.setTimeout(3000);
                // check latest log entry
                axios
                    .get(process.env.REACT_APP_API_SERVER_URL + "/api/latest_log_entry/" + serial_number, {})
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === "active" && response.data.lastheartbeat < 10) {
                            console.log("device ACTIVE!");
                            podCountDialogMessage.current.innerHTML = "Command Sent!";
                            podCountCloseButton.current.style.display = "inline";
                            device.remaining_pods = newPodCount;
                        } else {
                            podCountDialogMessage.current.innerHTML = "Device is not responding. Please try again.";
                            podCountDialogMessage.current.style.color = "red";
                            podCountDialogMessage.current.style.fontWeight = "800";
                            podCountCancelButton.current.style.display = "inline";
                            podCountUpdateButton.current.style.display = "inline";
                            podCountDialogContent.current.style.display = "inline";
                        }
                        return false;
                    })
                    .catch(() => {});
            })
            .catch(() => {});
    };

    const clearError = (serial_number) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/device/send_command/" + serial_number, {
                command: "clear_error",
                value: "",
            })
            .then(async () => {
                // wait for 3 seconds
                await timers.setTimeout(3000);
                // check latest log entry
                axios
                    .get(process.env.REACT_APP_API_SERVER_URL + "/api/latest_log_entry/" + serial_number, {})
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === "active" && response.data.lastheartbeat < 10) {
                            alert("Error cleared");
                            window.location.reload();
                        } else {
                            alert("Device is not responding. Please try again.");
                        }
                        return false;
                    })
                    .catch(() => {});
            })
            .catch(() => {});
    };

    const dispensePod = (serial_number) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/device/send_command/" + serial_number, {
                command: "dispense_pod",
                value: "",
            })
            .then(async () => {
                // wait for 3 seconds
                await timers.setTimeout(3000);
                // check latest log entry
                axios
                    .get(process.env.REACT_APP_API_SERVER_URL + "/api/latest_log_entry/" + serial_number, {})
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status === "active" && response.data.lastheartbeat < 10) {
                            alert("Pod Dispensed");
                        } else {
                            alert("Device is not responding. Please try again.");
                        }
                        return false;
                    })
                    .catch(() => {});
            })
            .catch(() => {});
    };

    const updatePodCountType = (strType) => {
        setSelectedPodCountType(strType);
        switch (strType) {
            case "add_to_total":
                setNewTotal.current.style.display = "none";
                addToTotal.current.style.display = "inline";
                break;
            case "change_total":
                addToTotal.current.style.display = "none";
                setNewTotal.current.style.display = "inline";
                break;
            case "":
                addToTotal.current.style.display = "none";
                setNewTotal.current.style.display = "none";
                break;
        }
    };

    useEffect(() => {
        getData();
        getClients();
        getCampaigns();
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/devices">
                                    <ArrowCircleLeftIcon /> Devices
                                </Link>
                            </li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}></Grid>
                </Grid>

                {device && (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "15px 30px", backgroundColor: "#FFF", width: "100%	" }}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <h2 className="campaignNameTitle">{device.serial_number}</h2>
                                </Grid>
                                <Grid item style={{ marginTop: "10px" }}>
                                    <span className="smallerHeader">
                                        <Circle className={device.heartbeat_status === 0 ? "circleRed" : "circleGreen"} /> ({device.status})
                                    </span>
                                </Grid>
                            </Grid>
                            <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Summary" {...a11yProps(0)} />
                                <Tab label="Status Log" {...a11yProps(1)} />
                                <Tab label="Operator Log" {...a11yProps(2)} />
                                <Tab label={"Issues (" + numIssues + ")"} {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <Box xs={12} className="tabPanelContent">
                            <TabPanel value={currentTab} index={0} fullWidth>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <h5>Base Configuration</h5>

                                        <Paper className="paddedPanel">
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Serial Number</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceSerialNumber} label={null} onBlur={(e) => saveDeviceField("serial_number")} onChange={(e) => updateDeviceField("serial_number", e.target.value)} />
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">MAC Address</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceMacAddress} label={null} onBlur={(e) => saveDeviceField("mac_address")} onChange={(e) => updateDeviceField("mac_address", e.target.value)} />
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Campaign</label>
                                                        {campaigns && (
                                                            <>
                                                                <Select name="campaign_uuid" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={device.campaign_uuid} onChange={(e) => updateDeviceField("campaign_uuid", e.target.value, true)}>
                                                                    {campaigns.map((campaign) => (
                                                                        <MenuItem key={campaign.id} value={campaign.campaign_uuid}>
                                                                            {campaign.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </>
                                                        )}
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <FormControl fullWidth>
                                                                <label className="formLabel">Status</label>
                                                                <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={device.status} label={null} disabled />
                                                                <br />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl fullWidth>
                                                                <label className="formLabel">Install Date</label>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        sx={{
                                                                            ".MuiInputBase-input": { padding: 1 },
                                                                        }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        style={{ backgroundColor: "#FFF" }}
                                                                        value={dayjs(deviceInstallDate)}
                                                                        onChange={() => undefined}
                                                                        onAccept={(e) => updateDeviceField("install_date", e, true)}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <FormControl fullWidth>
                                                                <label className="formLabel">Pods Remaining</label>
                                                                <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={device.remaining_pods} label={null} disabled />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <label className="formLabel">&nbsp;</label>
                                                            <br></br>
                                                            <Button variant="outlined" onClick={() => openPodCountDialog()}>
                                                                Update Count...
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <br />
                                        <h5>Install Details</h5>

                                        <Paper className="paddedPanel">
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Location Name</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceLocationName} label={null} onBlur={(e) => saveDeviceField("location_name")} onChange={(e) => updateDeviceField("location_name", e.target.value)} />
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Location Number</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceLocationNumber} label={null} onBlur={(e) => saveDeviceField("location_number")} onChange={(e) => updateDeviceField("location_number", e.target.value)} />
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Address</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceAddressStreet} label={null} onBlur={(e) => saveDeviceField("address_street")} onChange={(e) => updateDeviceField("address_street", e.target.value)} />
                                                        <br />
                                                        <Grid container spacing={1} fullWidth>
                                                            <Grid item xs={6}>
                                                                <label className="formLabel">City</label>
                                                                <FormControl fullWidth>
                                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceAddressCity} label={null} onBlur={(e) => saveDeviceField("address_city")} onChange={(e) => updateDeviceField("address_city", e.target.value)} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <label className="formLabel">State</label>
                                                                <FormControl fullWidth>
                                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceAddressState} label={null} onBlur={(e) => saveDeviceField("address_state")} onChange={(e) => updateDeviceField("address_state", e.target.value)} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <label className="formLabel">ZIP</label>
                                                                <FormControl fullWidth>
                                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={deviceAddressPostalCode} label={null} onBlur={(e) => saveDeviceField("address_postal_code")} onChange={(e) => updateDeviceField("address_postal_code", e.target.value)} />
                                                                    <br />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                        <br />
                                        <h5>Issues and Other Settings</h5>

                                        <Paper className="paddedPanel">
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Error Description</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={device.error_description} label={null} disabled />
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Error Code</label>
                                                        <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={device.error_code} label={null} disabled />
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                        <br />

                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <h5>Other Actions</h5>
                                                <Paper className="paddedPanel">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <br />
                                                            <Button className="submitButton danger" fullWidth onClick={() => removeDevice()}>
                                                                Remove Device
                                                            </Button>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <br />
                                                            {device.error_code ? (
                                                                <Button className="submitButton danger" fullWidth onClick={() => clearError(device.serial_number)}>
                                                                    Clear Error(s)
                                                                </Button>
                                                            ) : (
                                                                <Button className="btn grayButton" fullWidth disabled>
                                                                    Clear Error(s)
                                                                </Button>
                                                            )}
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button className="btn success" fullWidth onClick={() => dispensePod(device.serial_number)}>
                                                                Dispense Pod
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>

                                                <br />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {device.location_name && (
                                            <Paper className="qrCodeBox">
                                                Consumer_{device.serial_number}_{device.location_number}_{device.location_name.replace(" ", "")}_QR.png
                                                <br />
                                                <b>Consumer QR Code</b>
                                                <br />
                                                This is printed on the outside of the Pod Drop device, and consumers scan this to access the landing page for the connected campaign.
                                                <br />
                                                <br />
                                                {consumerQRCode && <img src={consumerQRCode} className="qrCodePreview" onClick={(e) => downloadQRImage(e.target.src, "Consumer_" + device.serial_number + "_" + device.location_number + "_" + device.location_name.replace(" ", "") + "")} />}
                                            </Paper>
                                        )}
                                        <br />
                                        <br />
                                        <Paper className="qrCodeBoxRed">
                                            OPERATOR_{device.serial_number}_QR_Code.png
                                            <br />
                                            <b>Operator QR Code:</b>
                                            <br />
                                            This is printed on the inside of the Pod Drop device, and is NOT to be distributed elsewhere.
                                            <br />
                                            <br />
                                            {operatorQRCode && <img src={operatorQRCode} className="qrCodePreview" onClick={(e) => "OPERATOR_" + downloadQRImage(e.target.src, "OPERATOR_" + device.serial_number + "")} />}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={currentTab} index={1} style={{ width: "100%" }}>
                                {deviceStatusLog && (
                                    <>
                                        <Grid container spacing={0}>
                                            <Grid item xs={9}>
                                                <h5>Status Log</h5>
                                            </Grid>
                                            <Grid item xs={3} style={{ textAlign: "right" }}>
                                                <CSVLink className="downloadbtn exportCSVButton" filename={device.serial_number + "_deviceStatusLog.csv"} data={deviceStatusLog}>
                                                    Export to CSV
                                                    <DownloadIcon />
                                                </CSVLink>
                                            </Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Time</TableCell>
                                                        <TableCell>Command/Status</TableCell>
                                                        <TableCell>Remaining Pods</TableCell>
                                                        <TableCell className="centered">Heartbeat</TableCell>
                                                        <TableCell className="centered">Status</TableCell>
                                                        <TableCell>Error Code</TableCell>
                                                        <TableCell>Error Reason</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {deviceStatusLog.map((logRow) => (
                                                        <TableRow>
                                                            <TableCell>{dateFormat(logRow.created_at, "mm/d/yyyy @ hh:MM:ssTT")}</TableCell>
                                                            <TableCell>{logRow.status}</TableCell>
                                                            <TableCell>{logRow.remaining_pods}</TableCell>
                                                            <TableCell className="centered">{logRow.status === "active" ? <Circle className="circleGreen" /> : <Circle className="circleRed" />}</TableCell>
                                                            <TableCell className="centered">{logRow.error_code ? <Circle className="circleRed" /> : <Circle className="circleGreen" />}</TableCell>
                                                            <TableCell>{logRow.error_code}</TableCell>
                                                            <TableCell>{logRow.error_description}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel value={currentTab} index={2} style={{ width: "100%" }}>
                                {deviceActionsLog && (
                                    <>
                                        <Grid container spacing={0}>
                                            <Grid item xs={9}>
                                                <h5>Operator Log</h5>
                                            </Grid>
                                            <Grid item xs={3} style={{ textAlign: "right" }}>
                                                <CSVLink className="downloadbtn exportCSVButton" filename={device.serial_number + "_deviceActionsLog.csv"} data={deviceActionsLog}>
                                                    Export to CSV
                                                    <DownloadIcon />
                                                </CSVLink>
                                            </Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Time</TableCell>
                                                        <TableCell>Operator</TableCell>
                                                        <TableCell>Command</TableCell>
                                                        <TableCell>Lat/Lng</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {deviceActionsLog.map((logRow) => (
                                                        <TableRow>
                                                            <TableCell>{dateFormat(logRow.created_at, "mm/d/yyyy @ hh:MM:ssTT")}</TableCell>
                                                            <TableCell>
                                                                {logRow.operator_first_name} {logRow.operator_last_name}
                                                            </TableCell>
                                                            <TableCell>{logRow.action_type}</TableCell>
                                                            <TableCell>
                                                                {logRow.latitude}, {logRow.longitude}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel value={currentTab} index={3} style={{ width: "100%" }}>
                                {issues && (
                                    <>
                                        <Grid container spacing={0}>
                                            <Grid item xs={9}>
                                                <h5>Issue Log</h5>
                                            </Grid>
                                            <Grid item xs={3} style={{ textAlign: "right" }}>
                                                <CSVLink className="downloadbtn exportCSVButton" filename={device.serial_number + "_issues.csv"} data={issues}>
                                                    Export to CSV
                                                    <DownloadIcon />
                                                </CSVLink>
                                            </Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Opened</TableCell>
                                                        <TableCell>Device Serial #</TableCell>
                                                        <TableCell>Issue Type</TableCell>
                                                        <TableCell>Issue Details</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Contact Name</TableCell>
                                                        <TableCell>Contact Email</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {issues.map((issue) => {
                                                        let issueType = issue.issue_type;
                                                        issueType = issueType.replace("_", " ");
                                                        issueType = issueType.toLocaleUpperCase();

                                                        return (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell>{dateFormat(issue.created_at, "mm/d/yyyy @ hh:MM:ssTT")}</TableCell>
                                                                    <TableCell>
                                                                        <Link to={"/devices/" + issue.serial_number}>
                                                                            {issue.serial_number.substring(0, issue.serial_number.length - 4)}
                                                                            <b>{issue.serial_number.substring(issue.serial_number.length - 4)}</b>
                                                                        </Link>
                                                                    </TableCell>
                                                                    <TableCell className="tableRowInfoCell">{issueType}</TableCell>
                                                                    <TableCell className="tableRowInfoCell">{issue.issue_details}</TableCell>
                                                                    <TableCell>
                                                                        {issue.issue_status && issue.issue_status !== null ? (
                                                                            <>
                                                                                {issue.issue_status === "open" ? (
                                                                                    <>
                                                                                        <div className="errorBold">{issue.issue_status.toUpperCase()}</div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="okBold">{issue.issue_status.toUpperCase()}</div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>{issue.contact_name}</TableCell>
                                                                    <TableCell>{issue.contact_email}</TableCell>
                                                                    <TableCell>
                                                                        {issue.issue_status === "open" ? (
                                                                            <>
                                                                                <Button onClick={() => resolveIssue(issue.id)} className="actionButton">
                                                                                    Resolve
                                                                                </Button>
                                                                                <Button className="grayButton actionButton" disabled>
                                                                                    Re-Open
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button className="grayButton actionButton" disabled>
                                                                                    Resolve
                                                                                </Button>
                                                                                <Button onClick={() => reopenIssue(issue.id)} className="actionButton">
                                                                                    Re-Open
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                )}
                            </TabPanel>
                        </Box>
                        <Dialog open={podCountDialogOpen} onClose={handleClose}>
                            <DialogTitle>Update Pod Count</DialogTitle>
                            <DialogContent style={{ minWidth: "350px", minHeight: "200px" }}>
                                <div ref={podCountDialogContent}>
                                    <div className="col-12">
                                        <div className="specLabel">
                                            How Do You Want To Update Count?<sup>*</sup>
                                        </div>
                                        <div className="specValue">
                                            <select className="form-control" id="updateType" name="pod_total_update_type" onChange={(e) => updatePodCountType(e.target.value)} value={selectedPodCountType}>
                                                <option value="">Select Update Type...</option>
                                                <option value="add_to_total">Add Pods to Total</option>
                                                <option value="change_total">Set New Total</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12" id="setNewTotal" style={{ display: "none" }} ref={setNewTotal}>
                                        <div className="specLabel">
                                            How many pods are loaded?<sup>*</sup>
                                        </div>
                                        <div className="specValue">
                                            <input className="form-control" type="number" name="total_pods_loaded" id="total_pods_loaded" placeholder="Enter # of Total Pods Loaded" value={totalPodsLoaded} ref={total_pods_loaded} onChange={(e) => setTotalPodsLoaded(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-12" id="addToTotal" style={{ display: "none" }} ref={addToTotal}>
                                        <div className="row">
                                            <div className="col-4" style={{ paddingTop: "7px" }}>
                                                Add
                                            </div>
                                            <div className="col-4">
                                                <input className="form-control" type="number" name="total_pods_added" id="total_pods_added" placeholder="# Pods" style={{ width: "100%" }} ref={total_pods_added} />
                                            </div>
                                            <div className="col-4" style={{ paddingTop: "7px" }}>
                                                to total
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ref={podCountDialogSending} style={{ display: "none" }}>
                                    <div ref={podCountDialogMessage}>Sending Command....</div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button className="cancelButton" onClick={handleClose} ref={podCountCancelButton}>
                                    Cancel
                                </Button>
                                <Button className="cancelButton" onClick={handleClose} ref={podCountCloseButton} style={{ display: "none" }}>
                                    Close
                                </Button>
                                <Button onClick={() => updatePodCount(device.serial_number)} ref={podCountUpdateButton}>
                                    Update Now
                                </Button>
                            </DialogActions>
                        </Dialog>{" "}
                    </>
                )}
            </Grid>
        </>
    );
}
