import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import dateFormat from "dateformat";

let currSortColumn = "";
let sortDirection = "";

export default function CustomTable({ data, columnFields, dataStateUpdate }) {
  const [currSort, setCurrSort] = useState("");
  const [currSortDir, setCurrSortDir] = useState("");

  const sortColumn = (columnName) => {
    console.log(columnName + " ... " + currSortColumn);
    const copyArray = [...data];
    if (currSortColumn == columnName) {
      if (sortDirection == "ASC") {
        sortDirection = "DESC";
      } else {
        sortDirection = "ASC";
      }
    } else {
      sortDirection = "ASC";
    }
    console.log("sortD: " + sortDirection);
    if (sortDirection == "ASC") {
      copyArray.sort((a, b) => (eval("a." + columnName) < eval("b." + columnName) ? -1 : 1));
    } else {
      copyArray.sort((a, b) => (eval("a." + columnName) < eval("b." + columnName) ? 1 : -1));
    }
    setCurrSortDir(sortDirection);
    currSortColumn = columnName;
    setCurrSort(currSortColumn);
    console.log(columnName + " ... " + currSortColumn);
    dataStateUpdate(copyArray);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              {columnFields.map((column, h) => (
                <>
                  <TableCell key={"customTable_header_" + h} onClick={() => sortColumn(column.field)} style={{ cursor: "pointer" }} className={currSort == column.field ? "selectedColumn" : "normalColumn"}>
                    {column.label}
                    {currSort == column.field ? (
                      currSortDir == "ASC" ? (
                        <>
                          <KeyboardArrowDown />
                        </>
                      ) : (
                        <>
                          <KeyboardArrowUp />
                        </>
                      )
                    ) : (
                      <>
                        <SwapVertIcon className="sortButtonWaiting" />
                      </>
                    )}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, r) => (
              <TableRow key={"customTable_row_" + r} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {columnFields.map((column, c) => {
                  switch (column.action_type) {
                    case "link":
                      if (column.custom_value) {
                        return (
                          <TableCell scope="row" className="tableRowInfoCell" key={"customTable_row_" + r + "_col_" + c}>
                            <Link to={column.link_prefix + row[column.link_field]}>{eval(column.custom_value)}</Link>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell scope="row" className="tableRowInfoCell" key={"customTable_row_" + r + "_col_" + c}>
                            <Link to={column.link_prefix + row[column.link_field]}>{row[column.field]}</Link>
                          </TableCell>
                        );
                      }
                    default:
                      switch (column.field_type) {
                        case "date":
                          return (
                            <TableCell scope="row" className="tableRowInfoCell" key={"customTable_row_" + r + "_col_" + c}>
                              {dateFormat(row[column.field], "mm/d/yyyy @ hh:MM:ssTT")}
                            </TableCell>
                          );
                        default:
                          if (column.custom_value) {
                            return (
                              <TableCell scope="row" className="tableRowInfoCell" key={"customTable_row_" + r + "_col_" + c}>
                                {eval(column.custom_value)}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell scope="row" className="tableRowInfoCell" key={"customTable_row_" + r + "_col_" + c}>
                                {row[column.field]}
                              </TableCell>
                            );
                          }
                      }
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
