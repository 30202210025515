import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Tab, Tabs, Typography, OutlinedInput, TextareaAutosize, CardContent } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
// import CustomAvatar from '../TableComponents/CustomAvatar'
// import { ReactComponent as RedArrow } from '../../assets/icons/High.svg'
// import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg'
// import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg'

const TaskInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px 8px 15px;
  min-height: 60px;
  border-radius: 5px;
  max-width: 100%;
  /* background: ${({ isDragging }) => (isDragging ? "rgba(255, 59, 59, 0.15)" : "white")}; */
  background: white;
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #e8e8e8;

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

const TaskCard = ({ item, index, column, editCampaignInput }) => {
  console.log(item);
  let itemWidth = "85%";
  if (column != "selected") {
    itemWidth = "100%";
  }
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <TaskInformation>
            <Grid container fullWidth>
              <Grid item style={{ width: itemWidth }}>
                {item.input_required ? (
                  <div className="requiredField" style={{ lineHeight: "1em", marginBottom: "2px", marginTop: "15px" }}>
                    {item.input_label}
                  </div>
                ) : (
                  <div style={{ lineHeight: "1em", marginBottom: "2px", marginTop: "15px" }}>{item.input_label}</div>
                )}

                <div className="secondary-details" style={{ width: "100%" }}>
                  <span style={{ width: "100%" }}>
                    <span style={{ display: "none" }} className="inputTextSize">
                      {item.input_text_size?.toUpperCase()}
                    </span>
                    <Grid container fullWidth>
                      <Grid item style={{ width: "85%" }}>
                        {item.input_type.toUpperCase()}
                      </Grid>
                      <Grid item style={{ width: "15%", textAlign: "right" }}>
                        {item.custom ? (
                          <>
                            <AccountBoxIcon />
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </span>
                </div>
              </Grid>
              {column == "selected" && (
                <Grid item style={{ textAlign: "right", width: "15%", marginTop: "15px" }}>
                  {column == "selected" ? <SettingsIcon style={{ cursor: "pointer", textAlign: "right" }} onClick={() => editCampaignInput(item.id, item.input_type, item.input_label, item.input_required)} /> : <></>}
                </Grid>
              )}
            </Grid>
          </TaskInformation>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
