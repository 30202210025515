import * as React from "react";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Tab, Tabs, Typography, OutlinedInput, TextareaAutosize, CardContent } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
let pageNumbers = [];

export default function Paginator({ totalRecords, nPages, currentPage, setCurrentPage, gotoPage, pageNumber, recordsPerPage, setPageSize }) {
    pageNumbers = [];
    for (let p = 1; p <= nPages; p++) {
        pageNumbers.push(p);
    }

    console.log(pageNumbers);

    const nextPage = () => {
        if (pageNumber !== nPages) gotoPage(parseInt(pageNumber) + 1);
    };
    const prevPage = () => {
        if (pageNumber !== 1) gotoPage(parseInt(pageNumber) - 1);
    };
    return (
        <>
            <Grid container style={{ backgroundColor: "#FFFFFF" }}>
                <Grid item xs="auto" style={{ textAlign: "left", marginTop: "17px", marginLeft: "10px", fontSize: "0.9em", fontWeight: "bold", backgroundColor: "#FFFFFF" }}>
                    {totalRecords} Records
                </Grid>
                <Grid item xs={6} style={{ flexGrow: "1" }}></Grid>
                <Grid item xs style={{ textAlign: "right", alignItems: "right" }}>
                    <TableContainer component={Paper} align="right">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Select value={recordsPerPage} onChange={(e) => setPageSize(e.target.value)} variant="outlined" size="small" style={{ fontSize: "1em", backgroundColor: "#FFF", marginLeft: "10px", marginRight: "10px", padding: "0px" }}>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="20">20</MenuItem>
                                        <MenuItem value="50">50</MenuItem>
                                        <MenuItem value="100">100</MenuItem>
                                    </Select>
                                    <span style={{ fontSize: "0.9em", marginTop: "5px" }}>Per page</span>
                                </TableCell>
                                <TableCell>
                                    <Button size="small" variant="outlined" onClick={() => prevPage()} style={{ backgroundColor: "#FFF" }}>
                                        Previous
                                    </Button>
                                </TableCell>
                                <TableCell> Page</TableCell>
                                <TableCell>
                                    <Select variant="outlined" size="small" style={{ fontSize: "1em", backgroundColor: "#FFF", marginLeft: "10px", marginRight: "10px", padding: "0px" }} onChange={(e) => gotoPage(e.target.value)} value={pageNumber}>
                                        {pageNumbers.map((pgNumber) => (
                                            <MenuItem key={pgNumber} value={pgNumber} style={{ backgroundColor: "#FFF" }}>
                                                {pgNumber}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell> of {nPages}</TableCell>
                                <TableCell>
                                    <Button size="small" variant="outlined" onClick={() => nextPage()} style={{ backgroundColor: "#FFF" }}>
                                        Next
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
}
