import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Grid, Paper, Typography, Box, MenuItem, Menu, Avatar, Divider, ListItemIcon, MenuList, ListItemText } from "@mui/material";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MicIcon from "@mui/icons-material/Mic";
import SourceIcon from "@mui/icons-material/Source";
import SettingsIcon from "@mui/icons-material/Settings";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import BusinessIcon from "@mui/icons-material/Business";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import MonitorIcon from "@mui/icons-material/Monitor";
import AccountMenu from "./AccountMenu";
import { addSearchFilter } from "../Util/Util";
import metadata from "../../metadata.json";

function Header({ setToken }) {
    const history = useNavigate();
    const location = useLocation();
    console.log(location);
    //console.log(history.location.pathname);
    const logout = () => {
        setToken("");
        history("/");
    };

    const changePassword = () => {
        history("/users/change_password");
    };

    let objUser = JSON.parse(sessionStorage.getItem("user"));
    console.log(objUser);

    const handleClick = () => {
        setToken("");
        history("/");
    };

    useEffect(() => {
        //        getWordCount();
    }, []);

    const resetClientUUIDSearchFilter = () => {
        addSearchFilter("client_uuid", "");
    };

    return (
        <>
            <img src="/assets/img/outform_logo_black.png" className="headerLogo" alt="logo" />
            <ul className="leftnavLinks">
                <li className={location.pathname === "/clients" || location.pathname === "/clients/" ? "leftnavLinkActive" : ""}>
                    <Link to="/clients">
                        <ListItemIcon>
                            <BusinessIcon fontSize="small" />
                        </ListItemIcon>
                        Clients
                    </Link>
                </li>
                <li className={location.pathname === "/campaigns" || location.pathname === "/campaigns/" ? "leftnavLinkActive" : ""}>
                    <Link to="/campaigns" onClick={() => resetClientUUIDSearchFilter()}>
                        <ListItemIcon>
                            <FormatListBulletedIcon fontSize="small" />
                        </ListItemIcon>
                        Campaigns
                    </Link>
                </li>
                <li className={location.pathname === "/users" || location.pathname === "/users/" ? "leftnavLinkActive" : ""}>
                    <Link to="/users">
                        <ListItemIcon>
                            <PeopleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        Users
                    </Link>
                </li>
                <li className={location.pathname === "/devices" || location.pathname === "/devices/" ? " leftnavLinkActive" : ""}>
                    <Link to="/devices">
                        <ListItemIcon>
                            <MonitorIcon fontSize="small" />
                        </ListItemIcon>
                        Devices
                    </Link>
                </li>
                <li className={location.pathname === "/settings/speech_adaptations" || location.pathname === "/settings/speech_adaptations/" ? " leftnavLinkActive" : ""}>
                    <Link to="/settings/speech_adaptations">
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </Link>
                </li>
            </ul>

            <Box className="leftBottomBox">
                <Paper className="progressBox">
                    <div className="row">
                        <div className="col-sm-12"></div>
                        <div className="col-sm-12">
                            <div className="progressBar"></div>
                        </div>
                        <div className="col-sm-12 smInfo">Renews on 10/01/2022</div>
                    </div>
                </Paper>
            </Box>
            <div className="versionInfo">
                {metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision}.{metadata.buildTag}
            </div>

            <AccountMenu label={objUser.first_name.substring(0, 1)} first_name={objUser.first_name} last_name={objUser.last_name} logout={logout} changePassword={changePassword} />
        </>
    );
}

Header.propTypes = {
    setToken: PropTypes.func.isRequired,
};

export default Header;
