import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Tab, Tabs, Typography, OutlinedInput, TextareaAutosize, CardContent } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams, Link } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Circle } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import dateFormat from "dateformat";
import { ca } from "date-fns/locale";
import { CardActions } from "@mui/material";
import Paginator from "../Util/Paginator";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";
import Board from "./Board";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImageIcon from "@mui/icons-material/Image";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

ChartJS.register(ArcElement, Tooltip, Legend);

const lists = ["todo", "inProgress", "done"];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

let devicesByStatus = {};

const chartOptions = {
    plugins: {
        legend: {
            position: "bottom",
            rtl: false,
            labels: {
                usePointStyle: true,
                pointStyle: "circle",
                padding: 10,
            },
        },
    },
};

export default function Campaign() {
    const [data2, setData2] = useState({
        labels: ["OK", "No Pods Detected", "Dispense Ball Sensor"],
        datasets: [
            {
                label: "# of Devices",
                data: [5, 1, 0],
                backgroundColor: ["green", "red", "orange"],
                borderColor: ["green", "red", "orange", "purple", "#888"],
                borderWidth: 1,
            },
        ],
    });

    const inputColumnComponent = useRef();

    let currentPage = 1;
    const [recordsPerPage, setRecordsPerPage] = useState(20);
    let currentCaret;

    let indexOfLastRecord = currentPage * recordsPerPage;
    let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const [currentRecords, setCurrentRecords] = useState(null);
    const [nPages, setNPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const [clients, setClients] = useState(null);
    const [issues, setIssues] = useState(null);
    const [leads, setLeads] = useState(null);
    const [numIssues, setNumIssues] = useState(0);
    const [numLeads, setNumLeads] = useState(0);
    const { campaign_uuid } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [campaignDialogTitle, setCampaignDialogTitle] = useState(null);
    const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
    const [campaignUUID, setCampaignUUID] = useState("new");
    const [currentTab, setCurrentTab] = useState(0);
    const [devices, setDevices] = useState(null);
    const [numDevices, setNumDevices] = useState(null);
    const [campaignStatus, setCampaignStatus] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [ageGateHeadline, setAgeGateHeadline] = useState("");
    const [ageGateFooter, setAgeGateFooter] = useState("");
    const [campaignOwners, setCampaignOwners] = useState("");
    const [deviceDispenseResults, setDeviceDispenseResults] = useState(null);
    const [totalDispenses, setTotalDispenses] = useState(null);
    const [campaignInputs, setCampaignInputs] = useState(null);

    const [customInputDialogOpen, setCustomInputDialogOpen] = useState(false);
    const [customInputDialogTitle, setCustomInputDialogTitle] = useState(null);
    const [customInputName, setCustomInputName] = useState("");
    const [customInputType, setCustomInputType] = useState("");
    const [customInputTextSize, setCustomInputTextSize] = useState("");
    const [customInputUUID, setCustomInputUUID] = useState("");

    const [campaignInputDialogOpen, setCampaignInputDialogOpen] = useState(false);
    const [campaignInputDialogTitle, setCampaignInputDialogTitle] = useState(null);
    const [campaignInputName, setCampaignInputName] = useState("");
    const [campaignInputType, setCampaignInputType] = useState("");
    const [campaignInputTextSize, setCampaignInputTextSize] = useState("");
    const [campaignInputUUID, setCampaignInputUUID] = useState("");
    const [campaignInputRequired, setCampaignInputRequired] = useState(false);

    const [showInputOptions, setShowInputOptions] = useState(false);
    const [customCampaignInputs, setCustomCampaignInputs] = useState(null);

    const [dailyLeadResults, setDailyLeadResults] = useState(null);

    const [inputOptions, setInputOptions] = useState(null);
    const [campaignMedia, setCampaignMedia] = useState(null);

    const [campaignMediaDialogOpen, setCampaignMediaDialogOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [campaignMediaUploadType, setCampaignMediaUploadType] = useState("");

    const [landingPage, setLandingPage] = useState("");
    const [continueText, setContinueText] = useState("");
    const [pageBackgroundColor, setPageBackgroundColor] = useState("");
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState("");
    const [buttonTextColor, setButtonTextColor] = useState("");
    const [leadInputHeaders, setLeadInputHeaders] = useState();
    const [addLinkOpen, setAddLinkOpen] = useState(false);

    const [campaignInputLink, setCampaignInputLink] = useState("");
    const [campaignInputLinkText, setCampaignInputLinkText] = useState("");

    const addLinkToText = (strLabel, strTarget) => {
        console.log(strLabel);
        console.log(strTarget);
        let originalText = campaignInputName;
        let newText = originalText.substring(0, currentCaret) + `<a href="${strTarget}">${strLabel}</a>` + originalText.substring(currentCaret, originalText.length);
        setCampaignInputName(newText);
    };

    const openAddLinkDialog = () => {
        var input = document.getElementById("campaignInputLabel");
        currentCaret = input.selectionStart;
        setCampaignInputDialogOpen(false);
        MySwal.fire({
            title: <p>Insert Link</p>,
            html: `
    <input type="text" id="linkLabel" class="swal2-input" placeholder="Link Label">
    <input type="text" id="linkTarget" class="swal2-input" placeholder="Link URL">
  `,
            cancelButtonText: "Cancel",
            confirmButtonText: "Add",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                addLinkToText(document.getElementById("linkLabel").value, document.getElementById("linkTarget").value);
                setCampaignInputDialogOpen(true);
            } else {
                setCampaignInputDialogOpen(true);
            }
        });
    };

    const closeAddLinkDialog = () => {
        setAddLinkOpen(false);
    };

    const saveAddLink = () => {
        window.clipboardData.setData("Text", "Copy this text to clipboard");
        setAddLinkOpen(false);
    };

    const [devicesByStatus, setDevicesByStatus] = useState({
        labels: ["Offline", "Online"],
        datasets: [
            {
                label: "# of Devices",
                data: [1, 1],
                backgroundColor: ["red", "green"],
                borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
                borderWidth: 1,
            },
        ],
    });

    let objSearchFilters = JSON.parse(sessionStorage.getItem("search_filters"));
    let offlineDevices = 0;
    let onlineDevices = 0;

    async function getDevices(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/devices_by_campaign/" + campaign_uuid, {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                offlineDevices = 0;
                onlineDevices = 0;
                setNumDevices(response.data.length);
                setDevices(response.data);

                let arrErrors = [0, 0, 0];

                for (let item of response.data) {
                    switch (item.error_code) {
                        case "DispenseBallSensor":
                            arrErrors[2]++;
                            break;
                        case "NoPodsDetected":
                            arrErrors[1]++;
                            break;
                        case "":
                            arrErrors[0]++;
                            break;
                        default:
                            arrErrors[0]++;
                            break;
                    }
                    if (item.heartbeat_status == "0") {
                        console.log("HB 0, offline now " + eval(offlineDevices + 1));
                        offlineDevices++;
                    } else {
                        console.log("HB 1, online now " + eval(onlineDevices + 1));
                        onlineDevices++;
                    }
                }

                console.log(response.data);

                setDevicesByStatus({
                    labels: ["Offline", "Online"],
                    datasets: [
                        {
                            label: "# of Devices",
                            data: [offlineDevices, onlineDevices],
                            backgroundColor: ["red", "green"],
                            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
                            borderWidth: 1,
                        },
                    ],
                });

                setData2({
                    labels: ["OK", "No Pods Detected", "Dispense Ball Sensor"],
                    datasets: [
                        {
                            label: "# of Devices",
                            data: arrErrors,
                            backgroundColor: ["green", "red", "orange"],
                            borderColor: ["green", "red", "orange"],
                            borderWidth: 1,
                        },
                    ],
                });
            })
            .catch(() => {});
    }

    const [distributor, setDistributor] = useState("Outform");

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    let arrTabs = ["Summary", "Content", "Issues", "Devices", "Analytics", "Leads"];

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const exportCSV = (data) => {
        const csvData = [["ID", "Name", "Username", "Email", "Phone", "Website"], ...data.map(({ id, name, username, email, phone, website }) => [id, name, username, email, phone, website])];
    };

    const handleDistributorChange = (e) => {
        setDistributor(e.target.value);
    };

    const handleCustomInputTypeChange = (e) => {
        setCustomInputType(e.target.value);
    };

    const handleCustomInputTextSizeChange = (e) => {
        setCustomInputTextSize(e.target.value);
    };

    const handleCampaignInputTypeChange = (e) => {
        if (e.target.value == "select") {
            setShowInputOptions(true);
        } else {
            setShowInputOptions(false);
        }
        setCampaignInputType(e.target.value);
    };

    const handleCampaignInputTextSizeChange = (e) => {
        setCampaignInputTextSize(e.target.value);
    };

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const removeCampaignMediaItem = async (campaign_media_uuid, campaign_uuid) => {
        console.log(campaign_media_uuid);
        await axios
            .delete(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_media/" + campaign_media_uuid, {})
            .then((response) => {
                getCampaignMedia(campaign_uuid);
                document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
            })
            .catch(() => {});
    };

    const updateCampaignName = (e) => {
        setCampaignName(e.target.value);
    };

    const updateAgeGateHeadline = (e) => {
        setAgeGateHeadline(e.target.value);
    };

    const updateCustomInputName = (e) => {
        setCustomInputName(e.target.value);
    };

    const updateCampaignInputName = (e) => {
        setCampaignInputName(e.target.value);
    };

    const updateCampaignInputRequired = (e) => {
        console.log("set required to " + e.target.checked);
        setCampaignInputRequired(e.target.checked);
    };

    async function getClients() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/clients", {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                setClients(response.data);
            })
            .catch(() => {});
    }

    const refreshLanding = () => {
        document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
    };

    async function getData() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaign/" + campaign_uuid, {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                setCampaign(response.data[0]);
                getClients();
                getDevices(response.data[0].campaign_uuid);
                getIssues(response.data[0].campaign_uuid);
                getLeads(response.data[0].campaign_uuid);
                getStats(response.data[0].campaign_uuid);
                getDailyLeads(response.data[0].campaign_uuid);
                setCampaignStatus(response.data[0].campaign_status);
                setCampaignName(response.data[0].name);
                setCampaignOwners(response.data[0].owners);
                setPageBackgroundColor(response.data[0].page_background_color);
                setAgeGateFooter(response.data[0].age_gate_footer);
                setAgeGateHeadline(response.data[0].age_gate_headline);
                setButtonBackgroundColor(response.data[0].button_background_color);
                setButtonTextColor(response.data[0].button_text_color);
                setLandingPage(response.data[0].landing_page);
                setContinueText(response.data[0].continue_text);
                getCampaignInputs(response.data[0].campaign_uuid);
                getCustomCampaignInputs(response.data[0].campaign_uuid);
                getCampaignMedia(response.data[0].campaign_uuid);
            })
            .catch(() => {});
    }

    async function getStats(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/consumer_dispenses/" + campaign_uuid, {})
            .then((response) => {
                let results = response.data;
                let totalDisp = 0;
                results.forEach((d) => {
                    let disp = d.pods_dispensed;
                    totalDisp = parseInt(totalDisp) + parseInt(disp);
                });
                setTotalDispenses(totalDisp);
                setDeviceDispenseResults(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function getCampaignInputs(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_inputs_only/" + campaign_uuid, { inputs_only: 1 })
            .then((response) => {
                console.log(response.data);
                setCampaignInputs(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function getCampaignInputOptions(campaign_input_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_input_options/" + campaign_input_uuid, {})
            .then((response) => {
                console.log(response.data);
                setInputOptions(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function getCampaignMedia(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_media/" + campaign_uuid, {})
            .then((response) => {
                let arrMedia = {};
                let results = response.data;
                results.forEach((d) => {
                    arrMedia[d.media_type] = d;
                });
                console.log(arrMedia);
                setCampaignMedia(arrMedia);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function getCustomCampaignInputs(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/custom_campaign_inputs/" + campaign_uuid, {})
            .then((response) => {
                console.log(response.data);
                setCustomCampaignInputs(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function getDailyLeads(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/daily_leads/" + campaign_uuid, {})
            .then((response) => {
                setDailyLeadResults(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function getIssues(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaign/issues/" + campaign_uuid, {})
            .then((response) => {
                console.log(response.data);
                setIssues(response.data);
                setNumIssues(response.data.length);
            })
            .catch(() => {});
    }

    const gotoPage = (n) => {
        console.log("go to page " + n);
        currentPage = n;
        indexOfLastRecord = currentPage * recordsPerPage;
        indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        console.log(indexOfFirstRecord, indexOfLastRecord);
        setPageNumber(n);
        setCurrentRecords(leads.slice(indexOfFirstRecord, indexOfLastRecord));
    };

    const setPageSize = (n) => {
        console.log("SET PAGE SIZE TO " + n);
        setRecordsPerPage(n);
        setPageNumber(1);
        indexOfLastRecord = currentPage * n;
        indexOfFirstRecord = indexOfLastRecord - n;
        console.log(indexOfFirstRecord, indexOfLastRecord);
        setCurrentRecords(leads.slice(indexOfFirstRecord, indexOfLastRecord));
        setNPages(Math.ceil(leads.length / n));
    };

    async function getLeads(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/lead_inputs/" + campaign_uuid, {})
            .then((response) => {
                let arrLeads = response.data;
                arrLeads.forEach((lead) => {
                    lead.inputs.forEach((leadInput) => {
                        lead["input_" + leadInput.input_order] = leadInput.value;
                    });
                    delete lead.inputs;
                });
                setLeads(arrLeads);
                console.log(arrLeads);
                setNumLeads(response.data.length);
                console.log(indexOfFirstRecord, indexOfLastRecord);
                setCurrentRecords(response.data.slice(indexOfFirstRecord, indexOfLastRecord));
                //        console.log(response.data.slice(indexOfFirstRecord, indexOfLastRecord));
                setNPages(Math.ceil(response.data.length / recordsPerPage));
                getLeadInputHeaders(campaign_uuid);
            })
            .catch(() => {});
    }

    async function getLeadInputHeaders(campaign_uuid) {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/lead_input_headers/" + campaign_uuid, {})
            .then((response) => {
                console.log(response.data);
                setLeadInputHeaders(response.data);
            })
            .catch(() => {});
    }

    const updateCampaignField = (k, v, save) => {
        campaign[k] = v;
        switch (k) {
            case "owners":
                setCampaignOwners(v);
                break;
            case "name":
                setCampaignName(v);
                break;
            case "landing_page":
                setLandingPage(v);
                break;
            case "age_gate_footer":
                setAgeGateFooter(v);
                break;
            case "age_gate_headline":
                setAgeGateHeadline(v);
                break;
            case "button_background_color":
                setButtonBackgroundColor(v);
                //                refreshLanding();
                break;
            case "button_text_color":
                setButtonTextColor(v);
                //                refreshLanding();
                break;
            case "page_background_color":
                setPageBackgroundColor(v);
                //               refreshLanding();
                break;
            case "continue_text":
                setContinueText(v);
                //             refreshLanding();
                break;
            case "input_required":
                setCampaignInputRequired(v);
                //           refreshLanding();
                break;
        }
        setCampaign(campaign);
        console.log(campaign);
        if (save) {
            saveCampaignField(k);
        }
    };

    const reopenIssue = (issueID) => {
        console.log("RESOLVE" + process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID, {
                k: "issue_status",
                v: "open",
            })
            .then(() => {
                getData();
            })
            .catch(() => {});
    };

    const resolveIssue = (issueID) => {
        console.log("RESOLVE" + process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_issue_field/" + issueID, {
                k: "issue_status",
                v: "resolved",
                userDetails: "test user",
            })
            .then(() => {
                getData();
            })
            .catch(() => {});
    };

    const saveCampaignField = (k) => {
        console.log(process.env.REACT_APP_API_SERVER_URL + "/api/update_campaign_field/" + campaign_uuid);
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_campaign_field/" + campaign_uuid, {
                k: k,
                v: campaign[k],
            })
            .then(() => {
                getData();
            })
            .catch(() => {});
    };

    const handleCampaignDialogOpen = (id, name = "") => {
        if (id === "new") {
            setCampaignDialogTitle("Add New Campaign");
        } else {
            setCampaignDialogTitle("Edit Campaign Details");
        }
        setCampaignUUID(id);
        setCampaignName(name);
        setCampaignDialogOpen(true);
        setDistributor("Outform");
    };

    const handleClose = () => {
        setCampaignDialogOpen(false);
    };

    const handleCampaignInputClose = () => {
        setCampaignInputDialogOpen(false);
    };

    const saveBaseCampaign = () => {
        if (campaignName != "") {
            const objUser = JSON.parse(sessionStorage.getItem("user"));
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaigns/save", {
                    campaign_name: campaignName,
                    campaign_uuid: campaignUUID,
                    distributor: distributor,
                })
                .then(() => {
                    handleClose();
                    getData();
                })
                .catch(() => {});
        } else {
            alert("Campaign Name is required.");
        }
    };

    var camalize = function camalize(str) {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    };

    const editCampaignInput = async (uuid, input_type, input_label, input_required) => {
        //
        let t = document.querySelectorAll('[data-rbd-draggable-id="' + uuid + '"] div div div div:last-child span')[0].innerText.toLowerCase();
        setCampaignInputName(document.querySelectorAll('[data-rbd-draggable-id="' + uuid + '"] div div div div:first-child')[0].innerText);
        setCampaignInputTextSize(document.querySelectorAll('[data-rbd-draggable-id="' + uuid + '"] span.inputTextSize')[0].innerText.toLowerCase());
        setCampaignInputType(t);
        setCampaignInputUUID(uuid);
        setCampaignInputRequired(document.querySelectorAll('[data-rbd-draggable-id="' + uuid + '"] div div div div:first-child')[0].classList.contains("requiredField"));
        console.log(input_required);
        if (t == "select" || t == "radio") {
            // get the input options
            setShowInputOptions(true);
            await getCampaignInputOptions(uuid);
            setCampaignInputDialogOpen(true);
        } else {
            setShowInputOptions(false);
            setCampaignInputDialogOpen(true);
        }
    };

    const saveCampaignInput = () => {
        if (campaignName != "") {
            const objUser = JSON.parse(sessionStorage.getItem("user"));
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_input/save", {
                    campaign_uuid: campaign_uuid,
                    campaign_input_uuid: campaignInputUUID,
                    input_type: campaignInputType,
                    input_text_size: campaignInputTextSize,
                    input_label: campaignInputName,
                    input_name: camalize(campaignInputName),
                    input_required: campaignInputRequired,
                })
                .then(() => {
                    console.log("SAVED");
                    handleCampaignInputClose();
                    document.querySelectorAll('[data-rbd-draggable-id="' + campaignInputUUID + '"] div div div div:first-child')[0].innerText = campaignInputName;
                    if (campaignInputRequired) {
                        document.querySelectorAll('[data-rbd-draggable-id="' + campaignInputUUID + '"] div div div div:first-child')[0].classList.add("requiredField");
                    } else {
                        document.querySelectorAll('[data-rbd-draggable-id="' + campaignInputUUID + '"] div div div div:first-child')[0].classList.remove("requiredField");
                    }
                    document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
                })
                .catch(() => {});
        } else {
            alert("Campaign Name is required.");
        }
    };

    const deleteInputOption = (optionUUID, inputUUID) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_input_options/delete", {
                campaign_input_option_uuid: optionUUID,
            })
            .then(() => {
                getCampaignInputOptions(inputUUID);
                document.querySelectorAll('[data-rbd-draggable-id="' + campaignInputUUID + '"] div div div div:first-child')[0].innerText = campaignInputName;
                document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
            })
            .catch(() => {});
    };

    const addInputOption = (u) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_input_options/add", {
                campaign_input_uuid: u,
            })
            .then(() => {
                getCampaignInputOptions(u);
                document.querySelectorAll('[data-rbd-draggable-id="' + campaignInputUUID + '"] div div div div:first-child')[0].innerText = campaignInputName;
                document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
            })
            .catch(() => {});
    };

    const saveInputOption = (field, value, optionUUID, inputUUID) => {
        axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaign_input_options/save", {
                campaign_input_option_uuid: optionUUID,
                field: field,
                value: value,
            })
            .then(() => {
                getCampaignInputOptions(inputUUID);
                document.querySelectorAll('[data-rbd-draggable-id="' + campaignInputUUID + '"] div div div div:first-child')[0].innerText = campaignInputName;
                document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
            })
            .catch(() => {});
    };

    const updateInputOption = (fld, uuid, v) => {
        const updatedInputOptions = inputOptions.map((obj) => {
            if (obj.campaign_input_option_uuid === uuid) {
                return { ...obj, [fld]: v };
            }
            return obj;
        });
        console.log(updatedInputOptions);
        setInputOptions(updatedInputOptions);
    };

    const saveCustomInput = () => {
        if (campaignName != "") {
            const objUser = JSON.parse(sessionStorage.getItem("user"));
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/custom_campaign_input/save", {
                    campaign_uuid: campaign_uuid,
                    campaign_input_uuid: customInputUUID,
                    input_type: customInputType,
                    input_text_size: customInputTextSize,
                    input_label: customInputName,
                    input_name: camalize(customInputName),
                })
                .then(() => {
                    setCustomInputDialogOpen(false);
                    inputColumnComponent.current.refreshInputFields({ custom: true, id: customInputUUID, input_label: customInputName, input_type: customInputType, input_name: camalize(customInputName) });
                    getCustomCampaignInputs();
                })
                .catch(() => {});
        } else {
            alert("custom Name is required.");
        }
    };

    const updateCampaignMedia = (mediaType) => {
        setCampaignMediaDialogOpen(true);
        setCampaignMediaUploadType(mediaType);
    };

    const onFileChange = (event) => {
        // Update the state
        setSelectedFile(event.target.files[0]);
    };

    // On file upload (click the upload button)
    const onFileUpload = async () => {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append("campaign_uuid", campaign_uuid);
        formData.append("media_type", campaignMediaUploadType);
        formData.append("imageFile", selectedFile, selectedFile.name);

        // Details of the uploaded file
        console.log(selectedFile);

        // Request made to the backend api
        // Send formData object
        await axios.post(process.env.REACT_APP_API_SERVER_URL + "/api/upload_campaign_image", formData);
        getCampaignMedia(campaign_uuid);
        document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
        setCampaignMediaDialogOpen(false);
    };

    // File content to be displayed after
    // file upload is complete
    const fileData = () => {
        if (selectedFile) {
            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {selectedFile.name}</p>

                    <p>File Type: {selectedFile.type}</p>

                    <p>Last Modified: {selectedFile.lastModifiedDate.toDateString()}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };
    const handleCustomInputDialogOpen = (id, name = "") => {
        if (id === "new") {
            setCustomInputDialogTitle("Add New Custom Input");
        } else {
            setCustomInputDialogTitle("Edit Custom Input Details");
        }
        setCustomInputUUID("new");
        setCampaignUUID(id);
        setCustomInputDialogOpen(true);
        setCustomInputType("text");
    };

    useEffect(() => {
        getData();
    }, []);

    let strCampaignListLink;
    if (objSearchFilters && objSearchFilters.client_uuid && objSearchFilters.client_uuid !== "") {
        strCampaignListLink = "/campaigns?client_uuid=" + objSearchFilters.client_uuid;
    } else {
        strCampaignListLink = "/campaigns";
    }

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>
                                <Link to={strCampaignListLink}>
                                    <ArrowCircleLeftIcon /> Campaigns
                                </Link>
                            </li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}></Grid>
                </Grid>

                {campaign && (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "15px 30px", backgroundColor: "#FFF", width: "100%	" }}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <h2 className="campaignNameTitle">{campaign.name}</h2>
                                    <h5 className="campaignNameTitle">{campaign.client_name}</h5>
                                </Grid>
                                <Grid item style={{ marginTop: "10px" }}>
                                    <span className="smallerHeader">
                                        ({campaign.online_devices} of {numDevices} devices online)
                                    </span>
                                </Grid>
                            </Grid>
                            <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Summary" {...a11yProps(0)} />
                                <Tab label="Content" {...a11yProps(1)} />
                                <Tab label={"Issues (" + numIssues + ")"} {...a11yProps(2)} />
                                <Tab label={"Devices (" + numDevices + ")"} {...a11yProps(3)} />
                                <Tab label="Analytics" {...a11yProps(4)} />
                                <Tab label={"Leads (" + numLeads + ")"} {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <Box className="tabPanelTitlebar">{arrTabs[currentTab]} </Box>
                        <Box xs={12} className="tabPanelContent">
                            <Grid container spacing={2}>
                                <TabPanel value={currentTab} index={0} fullWidth>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <label className="formLabel">Campaign Name</label>
                                                <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaignName} label={null} onBlur={(e) => saveCampaignField("name")} onChange={(e) => updateCampaignField("name", e.target.value)} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <label className="formLabel">Client</label>
                                                {clients && (
                                                    <>
                                                        <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaign.client_uuid} onChange={(e) => updateCampaignField("client_uuid", e.target.value, true)}>
                                                            {clients.map((client) => (
                                                                <MenuItem key={client.client_uuid} value={client.client_uuid}>
                                                                    {client.client_name}
                                                                </MenuItem>
                                                            ))}
                                                            <MenuItem value="poddrop" selected></MenuItem>
                                                        </Select>
                                                    </>
                                                )}
                                                <br />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <label className="formLabel">Owners</label>
                                                <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaignOwners} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("owners")} onChange={(e) => updateCampaignField("owners", e.target.value)} />
                                                <br />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <label className="formLabel">Status</label>
                                                <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaign.campaign_status} onChange={(e) => updateCampaignField("campaign_status", e.target.value, true)}>
                                                    <MenuItem key="Active" value="Active">
                                                        Active
                                                    </MenuItem>
                                                    <MenuItem key="Completed" value="Completed">
                                                        Completed
                                                    </MenuItem>
                                                    <MenuItem key="Pending" value="Pending">
                                                        Pending
                                                    </MenuItem>
                                                    <MenuItem key="Test" value="Test">
                                                        Test Mode
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Paper className="pieChartHolder">
                                                <h5>Devices By Status</h5>
                                                <br />
                                                <Pie data={devicesByStatus} options={chartOptions} />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className="pieChartHolder">
                                                <h5>Block Codes</h5>
                                                <br />
                                                <Pie data={data2} options={chartOptions} />{" "}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={currentTab} index={1} style={{ width: "100%" }}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <label className="formLabel">Assets</label>
                                                <Box className="campaignAssetsPanel">
                                                    <Grid container direction="row" justify="flex-end" alignItems="center" className="assetRow">
                                                        <Grid item xs={4} justify="flex-end" alignItems="center">
                                                            {campaignMedia && (
                                                                <>
                                                                    {campaignMedia.banner_image ? (
                                                                        <>
                                                                            <img src={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.banner_image.file_name} style={{ width: "50px" }} />
                                                                            <DeleteIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => removeCampaignMediaItem(campaignMedia.banner_image.campaign_media_uuid, campaign.campaign_uuid)}></DeleteIcon>
                                                                            <a href={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.banner_image.file_name} target="_blank">
                                                                                <DownloadIcon />
                                                                            </a>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ImageIcon style={{ width: "50px", opacity: "0.7" }} />
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6} justify="flex-end" alignItems="center">
                                                            <div>
                                                                <h6>Banner Image</h6>
                                                            </div>
                                                            <div className="descText">This image appears as the background image on all pages.</div>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ textAlign: "right" }}>
                                                            <UploadFileIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => updateCampaignMedia("banner_image")}></UploadFileIcon>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-end" alignItems="center" className="assetRow">
                                                        <Grid item xs={4} justify="flex-end" alignItems="center">
                                                            {campaignMedia && (
                                                                <>
                                                                    {campaignMedia.background_image ? (
                                                                        <>
                                                                            <img src={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.background_image.file_name} style={{ width: "50px" }} />
                                                                            <DeleteIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => removeCampaignMediaItem(campaignMedia.background_image.campaign_media_uuid, campaign.campaign_uuid)}></DeleteIcon>
                                                                            <a href={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.background_image.file_name} target="_blank">
                                                                                <DownloadIcon />
                                                                            </a>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ImageIcon style={{ width: "50px", opacity: "0.7" }} />
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6} justify="flex-end" alignItems="center">
                                                            <div>
                                                                <h6>Background Image</h6>
                                                            </div>
                                                            <div className="descText">This image appears as the background image on all pages.</div>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ textAlign: "right" }}>
                                                            <UploadFileIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => updateCampaignMedia("background_image")}></UploadFileIcon>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-end" alignItems="center" className="assetRow">
                                                        <Grid item xs={4} justify="flex-end" alignItems="center">
                                                            {campaignMedia && (
                                                                <>
                                                                    {campaignMedia.landing_image ? (
                                                                        <>
                                                                            <img src={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.landing_image.file_name} style={{ width: "50px" }} />
                                                                            <DeleteIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => removeCampaignMediaItem(campaignMedia.landing_image.campaign_media_uuid, campaign.campaign_uuid)}></DeleteIcon>
                                                                            <a href={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.landing_image.file_name} target="_blank">
                                                                                <DownloadIcon />
                                                                            </a>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ImageIcon style={{ width: "50px", opacity: "0.7" }} />
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6} justify="flex-end" alignItems="center">
                                                            <div>
                                                                <h6>Landing Page Image</h6>
                                                            </div>
                                                            <div className="descText">This image appears on the landing page between the banner and form content.</div>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ textAlign: "right" }}>
                                                            <UploadFileIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => updateCampaignMedia("landing_image")}></UploadFileIcon>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row" justify="flex-end" alignItems="center">
                                                        <Grid item xs={4} justify="flex-end" alignItems="center">
                                                            {campaignMedia && (
                                                                <>
                                                                    {campaignMedia.age_gate_image ? (
                                                                        <>
                                                                            <img src={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.age_gate_image.file_name} style={{ width: "50px" }} />
                                                                            <DeleteIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => removeCampaignMediaItem(campaignMedia.age_gate_image.campaign_media_uuid, campaign.campaign_uuid)}></DeleteIcon>
                                                                            <a href={"https://outformqr.com/landing/campaigns/" + campaign.campaign_uuid + "/img/" + campaignMedia.age_gate_image.file_name} target="_blank">
                                                                                <DownloadIcon />
                                                                            </a>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ImageIcon style={{ width: "50px", opacity: "0.7" }} />
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6} justify="flex-end" alignItems="center">
                                                            <div>
                                                                <h6>Age Gate Image</h6>
                                                            </div>
                                                            <div className="descText">This image appears at the top of the Age Gate, if enabled.</div>
                                                        </Grid>
                                                        <Grid item xs={2} style={{ textAlign: "right" }}>
                                                            <UploadFileIcon style={{ fontSize: "1.8em", cursor: "pointer" }} onClick={() => updateCampaignMedia("age_gate_image")}></UploadFileIcon>
                                                        </Grid>
                                                    </Grid>{" "}
                                                </Box>
                                                <br />
                                                <label className="formLabel">Age Gate</label>
                                                <Box className="campaignAssetsPanel">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <label className="formLabel">Enable Age Gate?</label>
                                                                <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaign.age_gate_enabled} onChange={(e) => updateCampaignField("age_gate_enabled", e.target.value, true)}>
                                                                    <MenuItem key="1" value="1">
                                                                        Yes
                                                                    </MenuItem>
                                                                    <MenuItem key="0" value="0">
                                                                        No
                                                                    </MenuItem>
                                                                </Select>
                                                                <br />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl fullWidth>
                                                                <label className="formLabel">Minimum Age</label>
                                                                <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaign.age_gate_minimum_age} onChange={(e) => updateCampaignField("age_gate_minimum_age", e.target.value, true)}>
                                                                    <MenuItem key="21" value="21">
                                                                        21
                                                                    </MenuItem>
                                                                    <MenuItem key="18" value="18">
                                                                        18
                                                                    </MenuItem>
                                                                </Select>
                                                                <br />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Headline</label>
                                                        <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={ageGateHeadline} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("age_gate_headline")} onChange={(e) => updateCampaignField("age_gate_headline", e.target.value)} />
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Footer Text</label>
                                                        <TextField multiline="true" rows="5" id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={ageGateFooter} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("age_gate_footer")} onChange={(e) => updateCampaignField("age_gate_footer", e.target.value)} />
                                                    </FormControl>
                                                </Box>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Pods Per Person</label>
                                                        <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value="once">
                                                            <MenuItem key="daily" value="daily">
                                                                Once Per Day
                                                            </MenuItem>
                                                            <MenuItem key="once" value="once" selected>
                                                                Only Once
                                                            </MenuItem>
                                                            <MenuItem key="Unlimited" value="Unlimited">
                                                                Unlimited
                                                            </MenuItem>
                                                        </Select>
                                                        <br />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Continue Button</label>
                                                        <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={continueText} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("continue_text")} onChange={(e) => updateCampaignField("continue_text", e.target.value)} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Background Color</label>
                                                        <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={pageBackgroundColor} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("page_background_color")} onChange={(e) => updateCampaignField("page_background_color", e.target.value)} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Button Color</label>
                                                        <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={buttonBackgroundColor} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("button_background_color")} onChange={(e) => updateCampaignField("button_background_color", e.target.value)} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <label className="formLabel">Button Text Color</label>
                                                        <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={buttonTextColor} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("button_text_color")} onChange={(e) => updateCampaignField("button_text_color", e.target.value)} />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <FormControl fullWidth>
                                                <label className="formLabel">Landing Page</label>
                                                <TextField id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={landingPage} InputLabelProps={{ shrink: true }} onBlur={(e) => saveCampaignField("landing_page")} onChange={(e) => updateCampaignField("landing_page", e.target.value)} />
                                                <br />
                                            </FormControl>

                                            <Box>
                                                {campaignInputs && customCampaignInputs && (
                                                    <>
                                                        <Board ref={inputColumnComponent} campaignInputs={campaignInputs} customCampaignInputs={customCampaignInputs} campaignUUID={campaign_uuid} handleCustomInputDialogOpen={handleCustomInputDialogOpen} editCampaignInput={editCampaignInput} />
                                                    </>
                                                )}
                                            </Box>

                                            <br />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box className="campaignTemplateWindow" style={{ width: "400px", height: "800px" }}>
                                                <center>
                                                    <Button variant="contained" class="btnRefreshLandingPage" color="primary" onClick={() => refreshLanding()} style={{ margin: "10px", color: "#FFFFFF" }}>
                                                        Refresh Landing Page
                                                    </Button>
                                                </center>{" "}
                                                <iframe id="campaignTemplateFrame" src={"https://admin.outformqr.com/landing/?c=" + campaign.campaign_uuid} width="100%" height="100%"></iframe>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={currentTab} index={2} style={{ width: "100%" }}>
                                    {issues && (
                                        <>
                                            <Grid container spacing={0}>
                                                <Grid item xs={9}></Grid>
                                                <Grid item xs={3} style={{ textAlign: "right" }}>
                                                    <CSVLink className="downloadbtn exportCSVButton" filename="issues.csv" data={issues}>
                                                        Export to CSV
                                                        <DownloadIcon />
                                                    </CSVLink>
                                                </Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="tableRowInfoCell">Opened</TableCell>
                                                            <TableCell className="tableRowInfoCell">Device Serial #</TableCell>
                                                            <TableCell className="tableRowInfoCell">Issue Type</TableCell>
                                                            <TableCell className="tableRowInfoCell">Issue Details</TableCell>
                                                            <TableCell className="tableRowInfoCell">Status</TableCell>
                                                            <TableCell className="tableRowInfoCell">Contact Name</TableCell>
                                                            <TableCell className="tableRowInfoCell">Contact Email</TableCell>
                                                            <TableCell className="tableRowInfoCell">Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {issues.map((issue) => {
                                                            let issueType = issue.issue_type;
                                                            issueType = issueType.replace("_", " ");
                                                            issueType = issueType.toLocaleUpperCase();

                                                            return (
                                                                <TableRow key={issue.id}>
                                                                    <TableCell className="tableRowInfoCell">{dateFormat(issue.created_at, "mm/d/yyyy @ hh:MM:ssTT")}</TableCell>
                                                                    <TableCell className="tableRowInfoCell">
                                                                        <Link to={"/devices/" + issue.serial_number}>
                                                                            {issue.serial_number.substring(0, issue.serial_number.length - 4)}
                                                                            <b>{issue.serial_number.substring(issue.serial_number.length - 4)}</b>
                                                                        </Link>
                                                                    </TableCell>
                                                                    <TableCell className="tableRowInfoCell">{issueType}</TableCell>
                                                                    <TableCell>{issue.issue_details}</TableCell>
                                                                    <TableCell className="tableRowInfoCell">
                                                                        {issue.issue_status && issue.issue_status !== null ? (
                                                                            <>
                                                                                {issue.issue_status === "open" ? (
                                                                                    <>
                                                                                        <div className="errorBold">{issue.issue_status.toUpperCase()}</div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="okBold">{issue.issue_status.toUpperCase()}</div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell className="tableRowInfoCell">{issue.contact_name}</TableCell>
                                                                    <TableCell className="tableRowInfoCell">{issue.contact_email}</TableCell>
                                                                    <TableCell className="tableRowInfoCell">
                                                                        {issue.issue_status === "open" ? (
                                                                            <>
                                                                                <Button onClick={() => resolveIssue(issue.id)} size="small" className="actionButton">
                                                                                    Resolve
                                                                                </Button>
                                                                                <Button className="actionButton grayButton" disabled size="small">
                                                                                    Re-Open
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button className="actionButton grayButton" disabled size="small">
                                                                                    Resolve
                                                                                </Button>
                                                                                <Button onClick={() => reopenIssue(issue.id)} size="small" className="actionButton">
                                                                                    Re-Open
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </TabPanel>
                                <TabPanel value={currentTab} index={3} style={{ width: "100%" }}>
                                    {devices && (
                                        <>
                                            <Grid container spacing={0}>
                                                <Grid item xs={9}></Grid>
                                                <Grid item xs={3} style={{ textAlign: "right" }}>
                                                    <CSVLink className="downloadbtn exportCSVButton" filename="devices.csv" data={devices}>
                                                        Export to CSV
                                                        <DownloadIcon />
                                                    </CSVLink>
                                                </Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Serial Number</TableCell>
                                                            <TableCell>MAC Address</TableCell>
                                                            <TableCell>APK</TableCell>
                                                            <TableCell>Campaign (Client)</TableCell>
                                                            <TableCell>Heartbeat</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Pods</TableCell>
                                                            <TableCell>Location (#)</TableCell>
                                                            <TableCell>City, State</TableCell>
                                                            <TableCell>Error Code</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {devices.map((device) => (
                                                            <TableRow key={device.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                                <TableCell scope="row">
                                                                    <Link to={"/devices/" + device.serial_number}>
                                                                        {device.serial_number.substring(0, device.serial_number.length - 6)}
                                                                        <b>{device.serial_number.substring(device.serial_number.length - 6)}</b>
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell scope="row">
                                                                    <Link to={"/devices/" + device.serial_number}>{device.mac_address}</Link>
                                                                </TableCell>
                                                                <TableCell scope="row"> {device.apk_version != "null" ? device.apk_version : <>---</>}</TableCell>
                                                                <TableCell scope="row" className="tableRowInfoCell">
                                                                    {device.campaign_name} ({device.client_name})
                                                                </TableCell>
                                                                <TableCell scope="row">
                                                                    <Circle className={device.heartbeat_status === 0 ? "circleRed" : "circleGreen"} /> {device.last_heartbeat ? dateFormat(device.last_heartbeat, "mm/d/yyyy @ hh:MM:ssTT") : "None"}
                                                                </TableCell>
                                                                <TableCell scope="row" className="tableRowInfoCell">
                                                                    {device.status === "active" ? (
                                                                        <>
                                                                            <div className="okBold">{device.status.toUpperCase()}</div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="errorBold">{device.status.toUpperCase()}</div>
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell scope="row">{device.remaining_pods}</TableCell>
                                                                <TableCell scope="row" className="tableRowInfoCell">
                                                                    {device.location_name ? (
                                                                        <>
                                                                            {device.location_name} ({device.location_number})
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell scope="row" className="tableRowInfoCell">
                                                                    {device.address_city}, {device.address_state}
                                                                </TableCell>
                                                                <TableCell scope="row" className="tableRowInfoCell">
                                                                    {device.error_code}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </TabPanel>
                                <TabPanel value={currentTab} index={4} style={{ minWidth: "100%" }} fullWidth>
                                    <Grid container spacing={2} style={{ minWidth: "100%" }} fullWidth>
                                        <Grid item xs={6}>
                                            <Card style={{ minWidth: "250px" }}>
                                                <CardContent>
                                                    <Typography sx={{ fontSize: 14, overflow: "visible" }} color="text.secondary" gutterBottom noWrap>
                                                        Total Pods Dispensed
                                                    </Typography>
                                                    <Typography variant="h3" component="div">
                                                        {totalDispenses}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions></CardActions>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Card sx={{ minWidth: 225 }}>
                                                <CardContent>
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        Devices
                                                    </Typography>
                                                    <Typography variant="h3" component="div">
                                                        {numDevices}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions></CardActions>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={2} fullWidth>
                                        <Grid item xs={6}>
                                            {deviceDispenseResults && (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={9}>
                                                            <h5>Pods Dispensed (by Device)</h5>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ textAlign: "right" }}>
                                                            <CSVLink className="downloadbtn exportCSVButton" filename="deviceDispenseResults.csv" data={deviceDispenseResults}>
                                                                Export to CSV
                                                                <DownloadIcon />
                                                            </CSVLink>
                                                        </Grid>
                                                    </Grid>
                                                    <TableContainer component={Paper}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Device</TableCell>
                                                                    <TableCell>Location (#)</TableCell>
                                                                    <TableCell>Pods</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {deviceDispenseResults.map((result) => (
                                                                    <TableRow key={result.serial_number}>
                                                                        <TableCell>{result.serial_number}</TableCell>
                                                                        <TableCell>
                                                                            {result.location_name} ({result.location_number})
                                                                        </TableCell>
                                                                        <TableCell>{result.pods_dispensed}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {dailyLeadResults && (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={9}>
                                                            <h5>Daily Leads</h5>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ textAlign: "right" }}>
                                                            <CSVLink className="downloadbtn exportCSVButton" filename="dailyLeadResults.csv" data={dailyLeadResults} title="Download">
                                                                Export to CSV
                                                                <DownloadIcon />
                                                            </CSVLink>
                                                        </Grid>
                                                    </Grid>
                                                    <TableContainer component={Paper}>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Date</TableCell>
                                                                    <TableCell>Lead Count</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {dailyLeadResults.map((result) => (
                                                                    <TableRow key={result.day}>
                                                                        <TableCell>{dateFormat(result.day + "T00:00:00", "mm/dd/yyyy")}</TableCell>
                                                                        <TableCell>{result.lead_count}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={currentTab} index={5} style={{ width: "100%" }}>
                                    {currentRecords && leadInputHeaders && (
                                        <>
                                            <Grid container spacing={0}>
                                                <Grid item xs={9}>
                                                    <h5>All Leads</h5>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: "right" }}>
                                                    <CSVLink className="downloadbtn exportCSVButton" filename="allLeadResults.csv" data={leads} title="Download">
                                                        Export to CSV
                                                        <DownloadIcon />
                                                    </CSVLink>
                                                </Grid>
                                            </Grid>
                                            {nPages && <Paginator nPages={nPages} currentPage={currentPage} gotoPage={gotoPage} pageNumber={pageNumber} totalRecords={numLeads} recordsPerPage={recordsPerPage} setPageSize={setPageSize} />}
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>
                                                                <div className="textContainer">Lead Source</div>
                                                            </TableCell>
                                                            <TableCell>Device</TableCell>
                                                            {leadInputHeaders.map((header) => (
                                                                <>
                                                                    <TableCell>
                                                                        <div className="textContainer">{header.input_label}</div>
                                                                    </TableCell>
                                                                </>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {currentRecords.map((lead) => (
                                                            <TableRow>
                                                                <TableCell>
                                                                    <div className="textContainer">{dateFormat(lead.created_at, "mm/d/yyyy @ hh:MM:ssTT")}</div>
                                                                </TableCell>
                                                                <TableCell>QR Code</TableCell>
                                                                <TableCell>
                                                                    <Link to={"/devices/" + lead.serial_number}>
                                                                        {lead.serial_number.substring(0, lead.serial_number.length - 4)}
                                                                        <b>{lead.serial_number.substring(lead.serial_number.length - 4)}</b>
                                                                    </Link>
                                                                </TableCell>
                                                                {lead.input_1 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_1}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_2 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_2}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_3 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_3}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_4 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_4}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_5 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_5}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_6 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_6}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_7 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_7}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_8 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_8}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_9 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_9}</div>
                                                                    </TableCell>
                                                                )}
                                                                {lead.input_10 && (
                                                                    <TableCell>
                                                                        <div className="textContainer">{lead.input_10}</div>
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </TabPanel>
                            </Grid>
                        </Box>
                    </>
                )}
            </Grid>

            <Dialog open={customInputDialogOpen} onClose={handleClose}>
                <DialogTitle>{customInputDialogTitle}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="normal"
                            type="text"
                            label="Input Label"
                            fullWidth
                            variant="standard"
                            onChange={updateCustomInputName}
                            onKeyUp={(event) => {
                                if (event.key === "Enter") saveCustomInput();
                            }}
                            value={customInputName}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Input Type</InputLabel>
                        <Select labelId="input_type" id="input_type" value={customInputType} label="Input Type" onChange={handleCustomInputTypeChange}>
                            <MenuItem key="checkbox" value="checkbox">
                                Checkbox
                            </MenuItem>
                            <MenuItem key="date" value="date">
                                Date
                            </MenuItem>
                            <MenuItem key="radio" value="radio">
                                Radio
                            </MenuItem>
                            <MenuItem key="select" value="select">
                                Select
                            </MenuItem>
                            <MenuItem key="text" value="text">
                                Text
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <br />
                        <br />
                        <InputLabel>Text Size</InputLabel>
                        <Select labelId="input_text_size" id="input_text_size" value={customInputTextSize} label="Text Size" onChange={handleCustomInputTextSizeChange}>
                            <MenuItem key="normal" value="normal">
                                Normal
                            </MenuItem>
                            <MenuItem key="small" value="small">
                                Small
                            </MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button className="cancelButton" onClick={() => setCustomInputDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={saveCustomInput}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={campaignInputDialogOpen} onClose={handleClose}>
                <DialogTitle>{campaignInputDialogTitle}</DialogTitle>
                <DialogContent style={{ width: "600px", height: "auto" }}>
                    <FormControl fullWidth>
                        <TextField autoFocus id="campaignInputLabel" margin="normal" type="text" label="Input Label" fullWidth variant="standard" onChange={updateCampaignInputName} value={campaignInputName} multiline={true} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Input Type</InputLabel>
                        <Select labelId="input_type" id="input_type" value={campaignInputType} label="Input Type" onChange={handleCampaignInputTypeChange}>
                            <MenuItem key="checkbox" value="checkbox">
                                Checkbox
                            </MenuItem>
                            <MenuItem key="date" value="date">
                                Date
                            </MenuItem>
                            <MenuItem key="radio" value="radio">
                                Radio
                            </MenuItem>
                            <MenuItem key="select" value="select">
                                Select
                            </MenuItem>
                            <MenuItem key="text" value="text">
                                Text
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl fullWidth>
                        <InputLabel>Text Size</InputLabel>
                        <Select labelId="input_text_size" id="input_text_size" value={campaignInputTextSize} label="Text Size" onChange={handleCampaignInputTextSizeChange}>
                            <MenuItem key="normal" value="normal">
                                Normal
                            </MenuItem>
                            <MenuItem key="small" value="small">
                                Small
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {showInputOptions ? (
                        <>
                            <FormControl fullWidth>
                                <Grid container>
                                    {inputOptions && (
                                        <>
                                            <div style={{ marginTop: "10px", width: "100%" }}>
                                                <b>Options</b>
                                                <Grid container spacing={2} fullWidth style={{ width: "100%" }}>
                                                    <Grid item style={{ width: "45%" }}>
                                                        LABEL
                                                    </Grid>
                                                    <Grid item style={{ width: "45%", paddingLeft: "25px" }}>
                                                        VALUE (input name in code)
                                                    </Grid>
                                                    <Grid item style={{ width: "10%", padding: "0px" }}>
                                                        &nbsp;
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {inputOptions.map((inputOption, i) => (
                                                <>
                                                    <Grid container spacing={2} fullWidth>
                                                        <Grid item style={{ width: "45%" }}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                id={inputOption.campaign_input_option_uuid}
                                                                type="text"
                                                                key={inputOption.campaign_input_option_uuid}
                                                                value={inputOption.option_label}
                                                                onBlur={(e) => saveInputOption("option_label", e.target.value, inputOption.campaign_input_option_uuid, inputOption.campaign_input_uuid)}
                                                                onChange={(e) => updateInputOption("option_label", inputOption.campaign_input_option_uuid, e.target.value)}
                                                                style={{ margin: "10px 0px 0px 0px" }}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ width: "45%" }}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                size="small"
                                                                id={inputOption.campaign_input_option_uuid}
                                                                type="text"
                                                                key={inputOption.campaign_input_option_uuid}
                                                                value={inputOption.option_value}
                                                                onBlur={(e) => saveInputOption("option_value", e.target.value, inputOption.campaign_input_option_uuid, inputOption.campaign_input_uuid)}
                                                                onChange={(e) => updateInputOption("option_value", inputOption.campaign_input_option_uuid, e.target.value)}
                                                                style={{ margin: "10px 0px 0px 0px" }}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{ width: "10%", padding: "34px 10px 0px 14px" }}>
                                                            <DeleteIcon style={{ cursor: "pointer" }} onClick={() => deleteInputOption(inputOption.campaign_input_option_uuid, inputOption.campaign_input_uuid)} />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ))}
                                            <Button onClick={() => addInputOption(campaignInputUUID)} style={{ marginTop: "15px", width: "90%", border: "1px solid #5599cc" }} size="small">
                                                Add Input Option
                                            </Button>
                                        </>
                                    )}
                                </Grid>
                            </FormControl>
                        </>
                    ) : (
                        <></>
                    )}
                    <FormControl fullWidth>
                        <FormControlLabel control={<Checkbox />} label={"Required"} checked={campaignInputRequired} onChange={updateCampaignInputRequired} />
                    </FormControl>

                    <FormControl>
                        Tools:
                        <Button variant="outlined" className="insertLinkButton" onClick={() => openAddLinkDialog()}>
                            Insert a Link
                        </Button>
                    </FormControl>

                    {addLinkOpen ? (
                        <>
                            <div>add link</div>
                            <br />
                            <br />
                            <Grid container spacing={2} fullWidth>
                                <Grid item style={{ width: "45%" }}>
                                    <TextField fullWidth variant="outlined" size="small" id={campaignInputUUID} type="text" key={campaignInputUUID} value={campaignInputLink} style={{ margin: "10px 0px 0px 0px" }} />
                                </Grid>
                                <Grid item style={{ width: "45%" }}>
                                    <TextField fullWidth variant="outlined" size="small" id={campaignInputUUID} type="text" key={campaignInputUUID} value={campaignInputLinkText} style={{ margin: "10px 0px 0px 0px" }} placeholder="Link Text" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} fullWidth>
                                <Grid item style={{ width: "45%" }}>
                                    <Button className="insertLinkButton" onClick={() => closeAddLinkDialog()} fullWidth>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item style={{ width: "45%" }}>
                                    <Button className="insertLinkButton" onClick={() => saveAddLink()} fullWidth>
                                        Copy Link to Clipboard
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button className="cancelButton" onClick={() => setCampaignInputDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={saveCampaignInput}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={campaignMediaDialogOpen} onClose={handleClose}>
                <DialogTitle>
                    Update Campaign Media
                    <Divider />
                    {campaignMediaUploadType.replace("_", " ").toUpperCase()}
                    <br />
                </DialogTitle>

                <DialogContent style={{ width: "600px", height: "auto" }}>
                    <div>
                        <input type="file" onChange={onFileChange} />
                        <Button className="cancelButton" onClick={() => setCampaignMediaDialogOpen(false)}>
                            Cancel
                        </Button>
                        &nbsp; &nbsp;
                        <Button className="uploadButton" onClick={onFileUpload}>
                            Upload Image
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={campaignDialogOpen} onClose={handleClose}>
                <DialogTitle>{campaignDialogTitle}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="normal"
                            type="text"
                            label="Campaign Name"
                            fullWidth
                            variant="standard"
                            onChange={updateCampaignName}
                            onKeyUp={(event) => {
                                if (event.key === "Enter") saveBaseCampaign();
                            }}
                            value={campaignName}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Distributor</InputLabel>
                        <Select labelId="distributor" id="distributor" value={distributor} label="Distributor" onChange={handleDistributorChange}>
                            <MenuItem key="distributor_0" value="Eyecom">
                                Eyecom
                            </MenuItem>
                            <MenuItem key="distributor_1" value="Heidia">
                                Heidia
                            </MenuItem>
                            <MenuItem key="distributor_2" value="Outform">
                                Outform
                            </MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button className="cancelButton" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={saveBaseCampaign}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
