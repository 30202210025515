import { React, useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Tab, Tabs, Typography, OutlinedInput, TextareaAutosize } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams, Link, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Circle } from "@mui/icons-material";
import timers from "timers-promises";
import dateFormat from "dateformat";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var bb = new Blob([ab]);
    return bb;
}

function downloadQRImage(dataURI, baseName) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    console.log("downloadQRImage");
    let b = dataURItoBlob(dataURI);
    var url = window.URL.createObjectURL(b);
    a.href = url;
    a.download = baseName + "_QR_Code.png";
    a.click();
    window.URL.revokeObjectURL(url);
}

export default function User() {
    const [user, setUser] = useState({ user_name: "", distributor: "" });
    const { id } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [organization, setOrganization] = useState("");

    const [userDistributor, setUserDistributor] = useState("");

    const history = useNavigate();

    const [consumerQRCode, setConsumerQRCode] = useState(null);
    const [operatorQRCode, setOperatorQRCode] = useState(null);
    const [swalProps, setSwalProps] = useState({});

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    let arrTabs = ["Summary", "Status History"];

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    async function getData() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/users/" + id, {
                company: "default",
            })
            .then((response) => {
                console.log(response.data);
                setUser(response.data[0]);
                setFirstName(response.data[0].first_name);
                setLastName(response.data[0].last_name);
                setEmail(response.data[0].email);
                setRole(response.data[0].role);
                setOrganization(response.data[0].organization);
            })
            .catch(() => {});
    }

    const removeUser = () => {
        MySwal.fire({
            title: <p>Remove this User?</p>,
            html: <p>Are you sure you want to remove this user?</p>,
            cancelButtonText: "Cancel",
            confirmButtonText: "Remove User",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(process.env.REACT_APP_API_SERVER_URL + "/api/user/remove", {
                        id: id,
                    })
                    .then(() => {
                        history("/users");
                    })
                    .catch(() => {});
            } else {
            }
        });
    };

    const saveUser = () => {
        if (firstName != "" || lastName != "" || email != "" || role != "" || organization != "") {
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/user/save", {
                    id: id,
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    organization: organization,
                    password: password,
                    role: role,
                })
                .then(() => {
                    history("/users");
                })
                .catch(() => {});
        } else {
            MySwal.fire({
                html: <p>Please enter the required fields.</p>,
                confirmButtonText: "OK",
            });
        }
    };

    useEffect(() => {
        if (id != "new") {
            getData();
        }
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/users">
                                    <ArrowCircleLeftIcon /> Users
                                </Link>
                            </li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}></Grid>
                </Grid>

                {user && (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "15px 15px", backgroundColor: "#FFF", width: "100%	" }}>
                            <h2 className="campaignNameTitle">Edit User</h2>
                        </Box>
                        <Box xs={12} className="tabPanelContent">
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <h5>User Details</h5>

                                    <Paper className="paddedPanel">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <label className="formLabel">First Name</label>
                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={firstName} onChange={(e) => setFirstName(e.target.value)} label={null} />
                                                    <br />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <label className="formLabel">Last Name</label>
                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={lastName} onChange={(e) => setLastName(e.target.value)} label={null} />
                                                    <br />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <label className="formLabel">Email</label>
                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={email} onChange={(e) => setEmail(e.target.value)} label={null} />
                                                    <br />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <label className="formLabel">Role</label>
                                                    <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={role} onChange={(e) => setRole(e.target.value)} displayEmpty>
                                                        <MenuItem value="" label="">
                                                            Select...
                                                        </MenuItem>
                                                        <MenuItem value="Admin" label="Admin">
                                                            Admin
                                                        </MenuItem>
                                                        <MenuItem value="Reporting" label="Reporting">
                                                            Reporting Only
                                                        </MenuItem>
                                                        <MenuItem value="User" label="User">
                                                            User
                                                        </MenuItem>
                                                    </Select>
                                                    <br />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <label className="formLabel">Organization</label>
                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={organization} onChange={(e) => setOrganization(e.target.value)} label={null} />
                                                    <br />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <label className="formLabel">Password (leave blank to use current password)</label>
                                                    <TextField hiddenLabel id="filled-basic" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} onChange={(e) => setPassword(e.target.value)} label={null} />
                                                    <br />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button className="submitButton" fullWidth onClick={() => saveUser()}>
                                                    Save User
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Link to="/users">
                                                    <Button className="cancelButton" fullWidth>
                                                        Cancel
                                                    </Button>
                                                </Link>
                                                <br />
                                                <br />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <br />
                                </Grid>
                            </Grid>
                            {id !== "new" ? (
                                <>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <h5>Other Actions</h5>
                                            <Paper className="paddedPanel">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <br />
                                                        <Button className="submitButton danger" fullWidth onClick={() => removeUser()}>
                                                            Remove User
                                                        </Button>
                                                        <br />
                                                        <br />
                                                    </Grid>
                                                </Grid>
                                            </Paper>

                                            <br />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </>
                )}
            </Grid>
        </>
    );
}
