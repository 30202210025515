import { React, useState, useEffect } from "react";
import axios from "axios";
import { Grid, Box } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Link } from "react-router-dom";
import CustomTable from "../Util/CustomTable";

export default function Users() {
  const [users, setUsers] = useState(null);

  let columnFields = [
    {
      field: "first_name",
      label: "Name",
      action_type: "link",
      link_prefix: "/users/",
      link_field: "id",
      custom_value: "row['first_name']+' '+row['last_name']+''",
    },
    {
      field: "email",
      label: "Email",
    },
    {
      field: "organization",
      label: "Organization",
    },
    {
      field: "role",
      label: "Role",
    },
    {
      field: "created_at",
      label: "Created",
      field_type: "date",
    },
  ];

  let objUser = JSON.parse(sessionStorage.getItem("user"));

  async function getData() {
    axios
      .get(process.env.REACT_APP_API_SERVER_URL + "/api/users", {
        company: "default",
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch(() => {});
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid container className="mainContainer">
        <Grid container id="page-head">
          <Grid item xs={7}>
            <ol className="breadcrumb">
              <li>Users</li>
            </ol>
          </Grid>
          <Grid item xs={5}>
            <div className="alignRight topMenuRight">
              <Link to="/users/new">
                <span className="btn-sm btn btn-success addButton">
                  <ControlPointIcon /> Add User
                </span>
              </Link>
            </div>
          </Grid>
        </Grid>

        <Box xs={12} className="mainPanel">
          <Grid container spacing={2}>
            {users && (
              <>
                <CustomTable data={users} columnFields={columnFields} dataStateUpdate={setUsers} />
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
