// Utility Functions
import dateFormat from "dateformat";

export const statusLabel = (i) => {
    switch (i) {
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        case 3:
            return "Disconnected";
    }
};

export const dateDisplay = (d, f) => {
    return dateFormat(d, f);
};

export const userLevelLabel = (lvl) => {
    let strLevel = "";
    switch (lvl) {
        case 1:
            strLevel = "System Admin";
            break;
        case 2:
            strLevel = "Admin";
            break;
        case 3:
            strLevel = "Agent";
            break;
        default:
            strLevel = "n/a";
            break;
    }
    return strLevel;
};

export const addSearchFilter = (strKey, v) => {
    let stuff = {};
    stuff[strKey] = v;
    sessionStorage.setItem("search_filters", JSON.stringify(stuff));
};
