import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { useParams, Link } from "react-router-dom";

import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Circle } from "@mui/icons-material";
import dateFormat from "dateformat";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addSearchFilter } from "../Util/Util";
import CustomTable from "../Util/CustomTable";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import Paginator from "../Util/Paginator";

let currSortColumn = "";
let sortDirection = "";

export default function Devices() {
    let history = useNavigate();
    const [devices, setDevices] = useState(null);
    const [clientDialogTitle, setDeviceDialogTitle] = useState(null);
    const [clientDialogOpen, setDeviceDialogOpen] = useState(false);
    const [clientName, setDeviceName] = useState("");
    const [clientUUID, setDeviceUUID] = useState("new");
    const [baseDeviceSerialNumber, setBaseDeviceSerialNumber] = useState("");
    const [baseDeviceMACAddress, setBaseDeviceMACAddress] = useState("");
    const [campaigns, setCampaigns] = useState(null);
    const [currentCampaignFilter, setCurrentCampaignFilter] = useState("");
    const [currSort, setCurrSort] = useState("");
    const [currSortDir, setCurrSortDir] = useState("");
    const [baseDeviceCampaignUUID, setBaseDeviceCampaignUUID] = useState("");
    const [numDevices, setNumDevices] = useState(0);

    let currentPage = 1;
    const [recordsPerPage, setRecordsPerPage] = useState(20);

    let indexOfLastRecord = currentPage * recordsPerPage;
    let indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const [currentRecords, setCurrentRecords] = useState(null);
    const [nPages, setNPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    const gotoPage = (n) => {
        console.log("go to page " + n);
        currentPage = n;
        indexOfLastRecord = currentPage * recordsPerPage;
        indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        console.log(indexOfFirstRecord, indexOfLastRecord);
        setPageNumber(n);
        setCurrentRecords(devices.slice(indexOfFirstRecord, indexOfLastRecord));
    };

    const setPageSize = (n) => {
        console.log("SET PAGE SIZE TO " + n);
        setRecordsPerPage(n);
        setPageNumber(1);
        indexOfLastRecord = currentPage * n;
        indexOfFirstRecord = indexOfLastRecord - n;
        console.log(indexOfFirstRecord, indexOfLastRecord);
        setCurrentRecords(devices.slice(indexOfFirstRecord, indexOfLastRecord));
        setNPages(Math.ceil(devices.length / n));
    };

    let columnFields = [
        {
            field: "serial_number",
            label: "Serial Number",
            action_type: "link",
            link_prefix: "/devices/",
            link_field: "serial_number",
        },
        {
            field: "mac_address",
            label: "MAC Address",
            action_type: "link",
            link_prefix: "/devices/",
            link_field: "serial_number",
        },
        {
            field: "apk_version",
            label: "APK Version",
        },
        {
            field: "campaign_name",
            label: "Campaign (Client)",
            custom_value: "row['campaign_name']+' ('+row['client_name']+')'",
        },
        {
            field: "last_heartbeat",
            label: "Heartbeat",
            field_type: "date",
        },
        {
            field: "status",
            label: "Status",
        },
        {
            field: "remaining_pods",
            label: "Pods",
        },
        {
            field: "location_name",
            label: "Loc. Name",
        },
        {
            field: "location_number",
            label: "Loc. #",
        },
        {
            field: "address_city",
            label: "City",
        },
        {
            field: "address_state",
            label: "State",
        },
        {
            field: "error_code",
            label: "Error Code",
        },
    ];

    const [searchParams] = useSearchParams();
    let campaign_uuid = searchParams.get("campaign_uuid"); // "testCode"

    const [distributor, setDistributor] = useState("Outform");

    const handleDistributorChange = (e) => {
        setDistributor(e.target.value);
    };

    let objUser = JSON.parse(sessionStorage.getItem("user"));
    let objSearchFilters = JSON.parse(sessionStorage.getItem("search_filters"));

    //    console.log(JSON.stringify(objSearchFilters));

    const updateDeviceName = (e) => {
        setDeviceName(e.target.value);
    };

    async function getCampaigns() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaigns", {
                company: "default",
            })
            .then((response) => {
                setCampaigns(response.data);
            })
            .catch(() => {});
    }

    async function getData() {
        setDevices(null);
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/devices", {
                company: "default",
            })
            .then((response) => {
                setDevices(response.data);
                setNumDevices(response.data.length);
                setNPages(Math.ceil(response.data.length / recordsPerPage));
                setCurrentRecords(response.data.slice(indexOfFirstRecord, indexOfLastRecord));
            })
            .catch(() => {});
    }

    async function getDevicesByCampaign() {
        setDevices(null);
        //   console.log("campaign_uuid=" + campaign_uuid);
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/devices_by_campaign/" + campaign_uuid, {
                company: "default",
            })
            .then((response) => {
                setDevices(response.data);
            })
            .catch(() => {});
    }

    const handleDeviceDialogOpen = (id, name = "") => {
        if (id === "new") {
            setDeviceDialogTitle("Add New Device");
        } else {
            setDeviceDialogTitle("Edit Device Details");
        }
        setDeviceUUID(id);
        setDeviceName(name);
        setDeviceDialogOpen(true);
        setDistributor("Outform");
    };

    const handleClose = () => {
        setDeviceDialogOpen(false);
    };

    const saveBaseDevice = () => {
        if (baseDeviceSerialNumber != "") {
            const objUser = JSON.parse(sessionStorage.getItem("user"));
            axios
                .post(process.env.REACT_APP_API_SERVER_URL + "/api/devices/save", {
                    serial_number: baseDeviceSerialNumber,
                    mac_address: baseDeviceMACAddress,
                    campaign_uuid: baseDeviceCampaignUUID,
                    new: true,
                })
                .then(() => {
                    setBaseDeviceSerialNumber("");
                    handleClose();
                    console.log("moving to devices/" + baseDeviceSerialNumber);
                    history("/devices/" + baseDeviceSerialNumber);
                })
                .catch(() => {});
        } else {
            alert("Serial Number is required.");
        }
    };

    const updateCurrentCampaignFilter = (cid) => {
        setCurrentCampaignFilter(cid);
        addSearchFilter("campaign_uuid", cid);
        if (cid !== "") {
            campaign_uuid = cid;
            console.log("campaign_uuid IS=" + campaign_uuid);
            getDevicesByCampaign();
        } else {
            getData();
        }
    };

    const sortColumn = (columnName) => {
        console.log(columnName + " ... " + currSortColumn);
        const copyArray = [...devices];
        if (currSortColumn == columnName) {
            if (sortDirection == "ASC") {
                sortDirection = "DESC";
            } else {
                sortDirection = "ASC";
            }
        } else {
            sortDirection = "ASC";
        }
        console.log("sortD: " + sortDirection);
        if (sortDirection == "ASC") {
            copyArray.sort((a, b) => (eval("a." + columnName) < eval("b." + columnName) ? -1 : 1));
        } else {
            copyArray.sort((a, b) => (eval("a." + columnName) < eval("b." + columnName) ? 1 : -1));
        }
        setCurrSortDir(sortDirection);
        currSortColumn = columnName;
        setCurrSort(currSortColumn);
        console.log(columnName + " ... " + currSortColumn);
        setDevices(copyArray);
    };

    useEffect(() => {
        if (objSearchFilters) {
            if (objSearchFilters.campaign_uuid && objSearchFilters.campaign_uuid !== "") {
                setCurrentCampaignFilter(objSearchFilters.campaign_uuid);
                campaign_uuid = objSearchFilters.campaign_uuid;
            }
        }

        if (campaign_uuid || (campaign_uuid !== "" && campaign_uuid !== null)) {
            //console.log("campaign_uuid IS=" + campaign_uuid);
            getDevicesByCampaign();
        } else {
            getData();
        }
        getCampaigns();
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>Devices</li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="alignRight topMenuRight">
                            <span onClick={() => handleDeviceDialogOpen("new", "")} className="btn-sm btn btn-success addButton">
                                <ControlPointIcon /> Add Device
                            </span>
                        </div>
                    </Grid>
                </Grid>

                <Box xs={12} className="filterPanel">
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <label className="formLabel">Filter by Campaign</label>
                                {campaigns && (
                                    <>
                                        <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={currentCampaignFilter} onChange={(e) => updateCurrentCampaignFilter(e.target.value)} displayEmpty>
                                            <MenuItem value="" label="All">
                                                All
                                            </MenuItem>
                                            {campaigns.map((campaign) => (
                                                <MenuItem key={campaign.id} value={campaign.campaign_uuid}>
                                                    {campaign.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>

                    {devices ? (
                        <>
                            <Grid container spacing={0} fullWidth>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6} style={{ textAlign: "right", paddingTop: "10px" }}>
                                    <CSVLink className="downloadbtn exportCSVButton" filename={currentCampaignFilter + "_devices.csv"} data={devices}>
                                        Export to CSV
                                        <DownloadIcon />
                                    </CSVLink>
                                </Grid>
                            </Grid>
                            {nPages && <Paginator nPages={nPages} currentPage={currentPage} gotoPage={gotoPage} pageNumber={pageNumber} totalRecords={numDevices} recordsPerPage={recordsPerPage} setPageSize={setPageSize} />}
                        </>
                    ) : (
                        <></>
                    )}
                </Box>

                <Box xs={12} className="mainPanel">
                    <Grid container spacing={2}>
                        {currentRecords && (
                            <>
                                <CustomTable data={currentRecords} columnFields={columnFields} sortColumn={sortColumn} currSort={currSort} currSortDir={currSortDir} />
                            </>
                        )}
                    </Grid>
                </Box>
            </Grid>

            <Dialog open={clientDialogOpen} onClose={handleClose}>
                <DialogTitle>{clientDialogTitle}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField autoFocus margin="normal" type="text" label="Serial Number" fullWidth variant="outlined" onChange={(e) => setBaseDeviceSerialNumber(e.target.value)} value={baseDeviceSerialNumber} />
                        <TextField autoFocus margin="normal" type="text" label="MAC Address" fullWidth variant="outlined" onChange={(e) => setBaseDeviceMACAddress(e.target.value)} value={baseDeviceMACAddress} />
                        {campaigns && (
                            <>
                                Campaign
                                <Select label="Campaign" name="campaign_uuid" variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={baseDeviceCampaignUUID} onChange={(e) => setBaseDeviceCampaignUUID(e.target.value)}>
                                    {campaigns.map((campaign) => (
                                        <MenuItem key={campaign.id} value={campaign.campaign_uuid}>
                                            {campaign.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button className="cancelButton" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={saveBaseDevice}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
