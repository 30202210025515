import React from "react";
// CSS
import "./Footer.css";

export default function Footer() {
    return (
        <>
            <footer id="footer"></footer>
        </>
    );
}
