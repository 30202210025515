import React, { useState, forwardRef, useImperativeHandle } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskCard from "./TaskCard";
import { v4 as uuidv4 } from "uuid";
import { Divider, Grid } from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Container = styled.div`
  display: flex;
`;

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 200px;
  border-radius: 5px;
  padding: 15px 0px;
  margin-left: 5px;
  margin-right: 5px;
  width: 50%;
`;

const TaskColumnStyles = styled.div`
  margin: 0px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;

const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`;

const Board = forwardRef((props, ref) => {
  let campaignUUID = props.campaignUUID;
  let customCampaignInputs = props.customCampaignInputs;
  let campaignInputs = props.campaignInputs;
  let handleCustomInputDialogOpen = props.handleCustomInputDialogOpen;
  let editCampaignInput = props.editCampaignInput;

  console.log("campaignUUID", campaignUUID);

  let data = [];
  let selectedFieldNames = [];
  console.log("custom", customCampaignInputs);

  campaignInputs.forEach((camp) => {
    data.push({ id: camp.campaign_input_uuid, input_label: camp.input_label, input_type: camp.input_type, input_name: camp.input_name, input_required: camp.input_required, input_text_size: camp.input_text_size });
    selectedFieldNames.push(camp.input_name);
    console.log(selectedFieldNames);
  });

  let availableFields = [
    {
      id: uuidv4() + "_new",
      input_name: "firstName",
      input_label: "First Name",
      input_type: "text",
      custom: false,
    },
    {
      id: uuidv4() + "_new",
      input_name: "lastName",
      input_label: "Last Name",
      input_type: "text",
      custom: false,
    },
    {
      id: uuidv4() + "_new",
      input_name: "emailAddress",
      input_label: "Email Address",
      input_type: "email",
      custom: false,
    },
    {
      id: uuidv4() + "_new",
      input_name: "dob",
      input_label: "Date of Birth",
      input_type: "date",
      custom: false,
    },
    {
      id: uuidv4() + "_new",
      input_name: "jobTitle",
      input_label: "Job Title",
      input_type: "text",
      custom: false,
    },
    {
      id: uuidv4() + "_new",
      input_name: "phoneNumber",
      input_label: "Phone Number",
      input_type: "phone",
      custom: false,
    },
    {
      id: uuidv4() + "_new",
      input_name: "zipCode",
      input_label: "ZIP Code",
      input_type: "text",
      custom: false,
    },
  ];

  customCampaignInputs.forEach((customInput) => {
    availableFields.push({ id: uuidv4() + "_new", input_name: customInput.input_name, input_label: customInput.input_label, input_type: customInput.input_type, custom: true });
  });

  let remainingFields = [];

  for (var row in availableFields) {
    if (!selectedFieldNames.includes(availableFields[row].input_name)) {
      remainingFields.push(availableFields[row]);
    }
  }

  availableFields = remainingFields;
  console.log("availableFields", availableFields);

  const columnsFromBackend = {
    [uuidv4()]: {
      id: "12345",
      title: "Selected Fields",
      name: "selected",
      items: data,
    },
    [uuidv4()]: {
      id: "12345324242",
      title: "Available Fields",
      name: "available",
      items: availableFields,
    },
  };

  const [columns, setColumns] = useState(columnsFromBackend);

  useImperativeHandle(ref, () => ({
    refreshInputFields(customInput) {
      console.log("refreshInputFields");
      availableFields.push({ id: uuidv4() + "_new", input_name: customInput.input_name, input_label: customInput.input_label, input_type: customInput.input_type, custom: true });
      setColumns(columnsFromBackend);
    },
  }));

  const updateCampaignInputs = async (inputs) => {
    let i = 1;

    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/update_campaign_inputs", {
        campaignInputs: inputs,
        campaign_uuid: campaignUUID,
      })
      .then(() => {
        document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
        return campaignUUID;
      })
      .catch(() => {});
  };

  const removeFromSelected = async (uuid) => {
    uuid = uuid.slice(0, 36);
    console.log("SENDING" + uuid);
    axios
      .post(process.env.REACT_APP_API_SERVER_URL + "/api/remove_campaign_input", {
        campaign_input_uuid: uuid,
      })
      .then(() => {
        document.getElementById("campaignTemplateFrame").src = document.getElementById("campaignTemplateFrame").src;
        return uuid;
      })
      .catch(() => {});

    //document.getElementById("campaignTemplateFrame").contentWindow.location.reload();
  };

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      if (destColumn.name == "selected") {
        await updateCampaignInputs(destItems);
        destItems.forEach((item) => {
          if (item.id.indexOf("_new") > -1) {
            item.id = item.id.replace("_new", "");
          }
        });
      } else {
        // run to remove this from DB
        console.log("source", source);
        destItems.forEach((item) => {
          if (item.id.indexOf("_new") == -1) {
            item.id = item.id + "_new";
          }
        });
        await removeFromSelected(destItems[destination.index].id);
        await updateCampaignInputs(sourceItems);
      }
      console.log(destItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      console.log("new", destColumn.name, destItems);
    } else {
      console.log("UPDATE CAMPAIGN INPUTS");
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
      // send new order up to db
      if (column.name == "selected") {
        await updateCampaignInputs(copiedItems);
      }
    }
  };
  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
      <Container>
        <TaskColumnStyles>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                    <Grid container>
                      <Grid item xs={9}>
                        <label className="formLabel">{column.title}</label>
                      </Grid>
                      <Grid item style={{ alignItems: "right" }} xs={3}>
                        {column.name == "available" ? (
                          <div style={{ textAlign: "right" }}>
                            <span onClick={() => handleCustomInputDialogOpen("new", "")} className="btn-sm btn actionButton">
                              Add
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                    <Divider />
                    {column.items.map((item, index) => (
                      <TaskCard key={item.id} item={item} index={index} column={column.name} editCampaignInput={editCampaignInput} />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            );
          })}
        </TaskColumnStyles>
      </Container>
    </DragDropContext>
  );
});

export default Board;
