import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Login.css";
import axios from "axios";
import metadata from "../../metadata.json";
import { userLevelLabel } from "../Util/Util";

import { useNavigate } from "react-router-dom";

function Login({ setToken }) {
    let history = useNavigate();
    console.log(history);

    sessionStorage.setItem("user", {});

    async function getUser() {
        return axios
            .post(process.env.REACT_APP_API_SERVER_URL + "/api/users/auth", {
                username: username,
                password: password,
            })
            .then((response) => response.data)
            .catch((e) => {
                console.log(e);
            });
    }

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const objLoggedInUser = await getUser({
            username,
            password,
        });
        if (!objLoggedInUser) {
            document.getElementById("loginMessage").innerHTML = "Invalid user. Try again.";
            return false;
        } else {
            objLoggedInUser.user_level_name = userLevelLabel(objLoggedInUser.user_level);
            if (objLoggedInUser.user_level == 1) {
                objLoggedInUser.admin_key = "05abb9625d9da566cfcfd88a2087361aa3f18f94";
            } else {
                objLoggedInUser.admin_key = "";
            }
            sessionStorage.setItem("user", JSON.stringify(objLoggedInUser));
            console.log(objLoggedInUser);
            setToken({ token: objLoggedInUser.token });
            history("/campaigns");
        }
    };

    return (
        <>
            <div id="LoginContainer" style={{ backgroundImage: `url("./assets/img/vector3.jpg")`, backgroundSize: "cover" }}></div>
            <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-5 mx-auto">
                        <div className="loginBox">
                            <img src="./assets/img/outform_logo.png" className="loginLogo" />
                            <br />
                            <br />
                            <br />

                            <form onSubmit={handleSubmit}>
                                <label>
                                    <label>Username</label>
                                    <input className="form-control noshadow" style={{ display: "inline" }} type="text" onChange={(e) => setUserName(e.target.value)} />
                                </label>
                                <label>
                                    <label>Password</label>
                                    <input className="form-control noshadow" style={{ display: "inline" }} type="password" onChange={(e) => setPassword(e.target.value)} />
                                </label>
                                <br />
                                <div id="loginMessage"></div>
                                <br />
                                <div>
                                    <button type="submit" className="btn btn-primary form-control">
                                        Sign In
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="versionInfoLogin">
                    {metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision}.{metadata.buildTag}
                </div>
            </div>
        </>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};

export default Login;
