import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useParams, Link, useSearchParams, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { addSearchFilter } from "../Util/Util";
import { Circle } from "@mui/icons-material";
import { Chip } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EnhancedTable from "../Util/EnhancedTable";

const MySwal = withReactContent(Swal);

export default function Campaigns() {
  const [campaigns, setCampaigns] = useState(null);
  const [campaignDialogTitle, setCampaignDialogTitle] = useState(null);
  const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignUUID, setCampaignUUID] = useState("new");
  const [clients, setClients] = useState(null);
  const [campaignClientUUID, setCampaignClientUUID] = useState("");

  const [searchParams] = useSearchParams();
  let history = useNavigate();

  let client_uuid = searchParams.get("client_uuid"); // "testCode"

  const [distributor, setDistributor] = useState("Outform");
  const [currentClientFilter, setCurrentClientFilter] = useState("");

  async function getClients() {
    axios
      .get(process.env.REACT_APP_API_SERVER_URL + "/api/clients", {
        company: "default",
      })
      .then((response) => {
        console.log(response.data);
        setClients(response.data);
      })
      .catch(() => {});
  }

  let objUser = JSON.parse(sessionStorage.getItem("user"));
  let objSearchFilters = JSON.parse(sessionStorage.getItem("search_filters"));

  console.log(JSON.stringify(objSearchFilters));

  const updateCampaignName = (e) => {
    setCampaignName(e.target.value);
  };

  async function getData() {
    axios
      .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaigns", {
        company: "default",
      })
      .then((response) => {
        setCampaigns(response.data);
      })
      .catch(() => {});
  }

  const removeCampaign = (campaignUUID) => {
    MySwal.fire({
      title: <p>Remove this Campaign?</p>,
      html: <p>Are you sure you want to remove this campaign?</p>,
      cancelButtonText: "Cancel",
      confirmButtonText: "Remove Campaign",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaign/remove", {
            campaign_uuid: campaignUUID,
          })
          .then(() => {
            if (objSearchFilters) {
              if (objSearchFilters.client_uuid && objSearchFilters.client_uuid !== "") {
                setCurrentClientFilter(objSearchFilters.client_uuid);
              }
            }
            if (client_uuid || (client_uuid !== "" && client_uuid !== null)) {
              getCampaignsByClient();
            } else {
              getData();
            }
            getClients();
          })
          .catch(() => {});
      } else {
      }
    });
  };

  async function getCampaignsByClient() {
    console.log("client_uuid=" + client_uuid);
    axios
      .get(process.env.REACT_APP_API_SERVER_URL + "/api/campaigns_by_client/" + client_uuid, {
        company: "default",
      })
      .then((response) => {
        setCampaigns(response.data);
      })
      .catch(() => {});
  }

  const handleCampaignDialogOpen = (id, name = "") => {
    if (id === "new") {
      setCampaignDialogTitle("Add New Campaign");
    } else {
      setCampaignDialogTitle("Edit Campaign Details");
    }
    setCampaignUUID(id);
    setCampaignName(name);
    setCampaignDialogOpen(true);
    setDistributor("Outform");
  };

  const handleClose = () => {
    setCampaignDialogOpen(false);
  };

  const saveBaseCampaign = () => {
    if (campaignName != "" && campaignClientUUID != "") {
      const objUser = JSON.parse(sessionStorage.getItem("user"));
      axios
        .post(process.env.REACT_APP_API_SERVER_URL + "/api/campaign/save", {
          campaign_name: campaignName,
          client_uuid: campaignClientUUID,
        })
        .then((result) => {
          history("/campaigns/" + result.data.campaign_uuid);
        })
        .catch(() => {});
    } else {
      alert("Campaign Name and Client are required.");
    }
  };

  useEffect(() => {
    if (objSearchFilters) {
      if (objSearchFilters.client_uuid && objSearchFilters.client_uuid !== "") {
        setCurrentClientFilter(objSearchFilters.client_uuid);
      }
    }
    if (client_uuid || (client_uuid !== "" && client_uuid !== null)) {
      getCampaignsByClient();
    } else {
      getData();
    }
    getClients();
  }, []);

  const updateCurrentClientFilter = (cid) => {
    setCurrentClientFilter(cid);
    addSearchFilter("client_uuid", cid);
    if (cid !== "") {
      client_uuid = cid;
      console.log("CLIETN IS=" + client_uuid);
      getCampaignsByClient();
    } else {
      getData();
    }
  };

  return (
    <>
      <Grid container className="mainContainer">
        <Grid container id="page-head">
          <Grid item xs={7}>
            <ol className="breadcrumb">
              <li>Campaigns</li>
            </ol>
          </Grid>
          <Grid item xs={5}>
            <div className="alignRight topMenuRight">
              <span onClick={() => handleCampaignDialogOpen("new", "")} className="btn-sm btn btn-success addButton">
                <ControlPointIcon /> Add Campaign
              </span>
            </div>
          </Grid>
        </Grid>

        <Box xs={12} className="filterPanel">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label className="formLabel">Filter by Client</label>
                {clients && (
                  <>
                    <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={currentClientFilter} onChange={(e) => updateCurrentClientFilter(e.target.value)} displayEmpty>
                      <MenuItem value="" label="All">
                        All
                      </MenuItem>
                      {clients.map((client) => (
                        <MenuItem key={client.client_uuid} value={client.client_uuid}>
                          {client.client_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Box xs={12} className="mainPanel">
          <Grid container spacing={2}>
            {campaigns && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Campaign</TableCell>
                      <TableCell>Client</TableCell>
                      <TableCell>Devices</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Owners</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaigns.map((campaign) => (
                      <TableRow key={campaign.campaign_uuid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell scope="row" className="tableRowInfoCell">
                          <Link to={"/campaigns/" + campaign.campaign_uuid}>
                            <b>{campaign.name}</b>
                          </Link>
                        </TableCell>
                        <TableCell scope="row" className="tableRowInfoCell">
                          {campaign.client_name}
                        </TableCell>
                        <TableCell scope="row" className="tableRowInfoCell">
                          <Chip label={campaign.online_devices} color="success" size="small" sx={{ paddingLeft: "3px", paddingRight: "3px" }} />
                          &nbsp;
                          <Chip label={campaign.offline_devices} color="error" size="small" sx={{ paddingLeft: "3px", paddingRight: "3px" }} />
                        </TableCell>
                        <TableCell scope="row" className="tableRowInfoCell">
                          {campaign.campaign_status}
                        </TableCell>
                        <TableCell scope="row" className="tableRowInfoCell">
                          {campaign.owners}
                        </TableCell>
                        <TableCell scope="row" className="tableRowInfoCell">
                          <Link to={"/campaigns/" + campaign.campaign_uuid}>
                            <Button size="small" variant="outlined" className="actionButton">
                              View Details
                            </Button>
                          </Link>
                          &nbsp;&nbsp;
                          <Link to="#">
                            <Button size="small" onClick={() => removeCampaign(campaign.campaign_uuid)} variant="outlined" className="actionButton" startIcon={<Delete />}>
                              Remove
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Box>
      </Grid>

      <Dialog open={campaignDialogOpen} onClose={handleClose}>
        <DialogTitle>{campaignDialogTitle}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              autoFocus
              margin="normal"
              type="text"
              label="Campaign Name"
              fullWidth
              variant="outlined"
              onChange={updateCampaignName}
              onKeyUp={(event) => {
                if (event.key === "Enter") saveBaseCampaign();
              }}
              value={campaignName}
            />
          </FormControl>
          <FormControl fullWidth>
            <label className="formLabel">Client</label>
            {clients && (
              <>
                <Select variant="outlined" size="small" style={{ backgroundColor: "#FFF" }} value={campaignClientUUID} onChange={(e) => setCampaignClientUUID(e.target.value)} displayEmpty fullWidth>
                  <MenuItem value="" label="Select">
                    Select
                  </MenuItem>
                  {clients.map((client) => (
                    <MenuItem key={client.client_uuid} value={client.client_uuid}>
                      {client.client_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button className="cancelButton" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={saveBaseCampaign}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
