import { React, useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { useParams, Link } from "react-router-dom";

import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { addSearchFilter } from "../Util/Util";

export default function Clients() {
    const [clients, setClients] = useState(null);
    const [clientDialogTitle, setClientDialogTitle] = useState(null);
    const [clientDialogOpen, setClientDialogOpen] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientUUID, setClientUUID] = useState("new");

    const [distributor, setDistributor] = useState("Outform");

    const handleDistributorChange = (e) => {
        setDistributor(e.target.value);
    };

    let objUser = JSON.parse(sessionStorage.getItem("user"));

    const updateClientName = (e) => {
        setClientName(e.target.value);
    };

    async function getData() {
        axios
            .get(process.env.REACT_APP_API_SERVER_URL + "/api/clients", {
                company: "default",
            })
            .then((response) => {
                setClients(response.data);
            })
            .catch(() => {});
    }

    const handleClientDialogOpen = (id, name = "") => {
        if (id === "new") {
            setClientDialogTitle("Add New Client");
        } else {
            setClientDialogTitle("Edit Client Details");
        }
        setClientUUID(id);
        setClientName(name);
        setClientDialogOpen(true);
        setDistributor("Outform");
    };

    const handleClose = () => {
        setClientDialogOpen(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Grid container className="mainContainer">
                <Grid container id="page-head">
                    <Grid item xs={7}>
                        <ol className="breadcrumb">
                            <li>Clients</li>
                        </ol>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="alignRight topMenuRight">
                            <Link to="/clients/new">
                                <span className="btn-sm btn btn-success addButton">
                                    <ControlPointIcon /> Add Client
                                </span>
                            </Link>
                        </div>
                    </Grid>
                </Grid>

                <Box xs={12} className="mainPanel">
                    <Grid container spacing={2}>
                        {clients && (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Client Name</TableCell>
                                            <TableCell>Distributor</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clients.map((client) => (
                                            <TableRow key={client.client_uuid} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                <TableCell scope="row" className="tableRowInfoCell">
                                                    <Link to={"/campaigns?client_uuid=" + client.client_uuid} onClick={() => addSearchFilter("client_uuid", client.client_uuid)}>
                                                        <b>{client.client_name}</b>
                                                    </Link>
                                                </TableCell>
                                                <TableCell scope="row" className="tableRowInfoCell">
                                                    {client.distributor}
                                                </TableCell>
                                                <TableCell scope="row" className="tableRowInfoCell">
                                                    <Link to={"/campaigns?client_uuid=" + client.client_uuid} onClick={() => addSearchFilter("client_uuid", client.client_uuid)}>
                                                        <Button size="small" variant="outlined" className="actionButton">
                                                            View Campaigns
                                                        </Button>
                                                    </Link>
                                                    <Link to={"/clients/" + client.client_uuid} onClick={() => addSearchFilter("client_uuid", client.client_uuid)}>
                                                        <Button size="small" variant="outlined" className="actionButton">
                                                            Edit Client
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Box>
            </Grid>
        </>
    );
}
