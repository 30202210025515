import "./QROIAdmin.css";
//import "./darkMode.css";
import "./panels.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Login from "../Login/Login";
import Home from "../Home/Home";
import Campaigns from "../Campaigns/Campaigns";
import Campaign from "../Campaigns/Campaign";
import Clients from "../Clients/Clients";
import Client from "../Clients/Client";
import Devices from "../Devices/Devices";
import Device from "../Devices/Device";
import Users from "../Users/Users";
import User from "../Users/User";
import ChangePassword from "../Users/ChangePassword";
import useToken from "./useToken";
import { Grid } from "@mui/material";

const QROIAdmin = () => {
    const { token, setToken } = useToken();
    if (!token) {
        return (
            <BrowserRouter>
                <Login setToken={setToken} />
                <Footer />
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <Grid container spacing={0}>
                    <Grid item id="leftnav" style={{ height: "100vh" }}>
                        <Header setToken={setToken} />
                    </Grid>
                    <Grid item id="mainColumn" style={{ width: "100% !important" }}>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/clients" element={<Clients />} />
                            <Route exact path="/clients/:client_uuid" element={<Client />} />
                            <Route exact path="/campaigns" element={<Campaigns />} />
                            <Route exact path="/devices" element={<Devices />} />
                            <Route exact path="/devices/:device_uuid" element={<Device />} />
                            <Route exact path="/campaigns/:campaign_uuid" element={<Campaign />} />
                            <Route exact path="/users" element={<Users />} />
                            <Route exact path="/users/:id" element={<User />} />
                            <Route exact path="/users/change_password" element={<ChangePassword />} />
                        </Routes>
                    </Grid>
                </Grid>
                <Footer />
            </BrowserRouter>
        );
    }
};

export default QROIAdmin;
