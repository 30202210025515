import { React, useState, useEffect, useRef } from "react";
import { Grid, Paper, TextField, Box, Button, Stack, Card, Divider, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Tab, Tabs, Typography, OutlinedInput, TextareaAutosize } from "@mui/material";

export default function Home() {
    return (
        <Grid container className="mainContainer">
            <Grid container id="page-head">
                <Grid item xs={7}>
                    <ol className="breadcrumb">
                        <li>Home</li>
                    </ol>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "20px", fontSize: "18px" }}>
                Welcome... we'll likely go to Campaigns from here, but this might also be a spot for rollups.
            </Grid>
        </Grid>
    );
}
